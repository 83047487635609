import React from 'react';

const Button = (props) => {
  const { onClick = () => {}, type = 'button', primary = false, secondary = false, disabled = false, children, fullwidth = false, tooltip = '', dataUkToggle, className } = {
    ...props,
  };
  return (
    <button
      type={type}
      data-uk-toggle={dataUkToggle}
      uk-tooltip={`title:${tooltip}`}
      className={`${className} uk-button uk-button-${primary ? 'primary' : secondary ? 'secondary' : 'default'} ${fullwidth ? 'uk-width-1-1' : ''}`}
      disabled={disabled}
      onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
