import React from 'react';
import renderHTML from 'react-render-html';
import { MdClose } from 'react-icons/md';

import Modal from 'components/ReactModal';

import './description-modal.css';

const DescriptionModal = ({ modalIsOpen, closeModal, description }) => {
  return (
    <Modal modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <MdClose className="description-modal-close" onClick={closeModal} />
      <section className="description-modal-container">{renderHTML(description)}</section>
    </Modal>
  );
};

export default DescriptionModal;
