import React from 'react';
import moment from 'moment';
import 'moment/locale/es';
import ImageGhost from 'components/ImageGhost';
import ImageChatBox from 'components/ImageChatBox';

const ChatBox = (props) => {
  const { children, isLeft, author, time, avatar } = { ...props };
  return (
    <div className={'uk-flex uk-flex-bottom uk-margin-bottom'}>
      {isLeft && avatar && <ImageChatBox author={author} avatar={avatar} isLeft={isLeft}/>}
      {isLeft && !avatar && <ImageGhost isLeft={isLeft}/>}
      <div className={'uk-card uk-card-default uk-width-1-2@m uk-padding-small'}>
        <div className={'uk-flex uk-flex-between'}>
          <p className={'uk-margin-remove uk-text-bold uk-text-small'}>{author}</p>
          <p className={'uk-comment-meta uk-margin-remove uk-text-small'}>{moment(time).format('hh:mm a')}</p>
        </div>
        <div>{children}</div>  
      </div>
      {!isLeft && avatar && <ImageChatBox author={author} avatar={avatar} isLeft={isLeft}/>}
      {!isLeft && !avatar && <ImageGhost isLeft={isLeft}/>}
    </div>   
  );
};

export default ChatBox;