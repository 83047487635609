import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18n from 'config/i18n';
import { IoIosLogOut, IoIosNotifications, IoMdSettings } from 'react-icons/io';
import { BsDot } from 'react-icons/bs';
import { BiCalendarAlt } from 'react-icons/bi';
import { MdChatBubble } from 'react-icons/md';

import { logout } from 'store/actions/auth';
import { ROUTES } from 'utils/constants';
import { getPatientAvatar } from 'utils/getPatientAvatar';
import { useWindowSize } from 'utils/hooks';
import { useToggle } from 'utils/hooks';

import AlertModal from 'components/AlertModal';

import heartLogo from 'assets/images/heart-logo.png';

import './nav.css';

const Nav = () => {
  const { plan = {} } = useSelector((state) => {
    const { patient = {} } = state;
    return patient;
  });
  const { patient = {} } = plan;
  const { name = '', gender = '', clinic = {}, notifications = [] } = { ...patient };
  const { profile: clinicProfile = {} } = { ...clinic };
  const { hasCalls = false } = { ...clinicProfile };

  const dispatch = useDispatch();
  const { push, replace, location } = useHistory();
  const { pathname } = location;

  const { width } = useWindowSize();
  const isIpadBreakpoint = width >= 768;

  const [currentPath, setCurrentPath] = useState('');
  const [alertLeave, setIsAlertLeave] = useToggle();

  const isInCircuit = [ROUTES.NEW_CIRCUIT, ROUTES.LECTURES].includes(pathname);
  const onChangeRoute = (path) => {
    if (isInCircuit) {
      setIsAlertLeave();
      setCurrentPath(path);
    } else {
      replace(path);
    }
  };

  const onLogout = (path) => {
    if (isInCircuit) {
      setIsAlertLeave();
      setCurrentPath(path);
    } else {
      dispatch(logout());
    }
  };

  const handleConfirm = () => {
    if (currentPath === ROUTES.LOGIN) {
      dispatch(logout());
    } else {
      push(currentPath);
    }
  };

  return (
    <>
      <nav className="nav-container">
        <div className="nav-logo">
          <button onClick={() => onChangeRoute(ROUTES.HOME)} className="nav-logo-heart">
            <img src={heartLogo} alt="healthphy-logo" />
          </button>
        </div>
        <div className="nav-navigation">
          <button className={pathname === ROUTES.HOME ? 'nav-item-selected' : 'nav-item'} onClick={() => onChangeRoute(ROUTES.HOME)}>
            <BiCalendarAlt className="nav-icon" />
          </button>

          {hasCalls && (
            <button
              className={pathname === ROUTES.CONTACT ? 'nav-item-selected' : 'nav-item'}
              onClick={() => onChangeRoute(ROUTES.CONTACT)}>
              <MdChatBubble className="nav-icon" />
            </button>
          )}
          <button
            onClick={() => onChangeRoute(ROUTES.NOTIFICATIONS)}
            className={`nav-noti ${pathname === ROUTES.NOTIFICATIONS ? 'nav-item-selected' : 'nav-item'}`}>
            <IoIosNotifications className="nav-icon" />
            {notifications.length > 0 && <BsDot className="nav-noti-dot" />}
          </button>

          <button
            className={pathname === ROUTES.SETTINGS ? 'nav-item-selected' : 'nav-item'}
            onClick={() => onChangeRoute(ROUTES.SETTINGS)}>
            <IoMdSettings className="nav-icon" />
          </button>
        </div>
        <div className="nav-user">
          {isIpadBreakpoint && <img src={getPatientAvatar(gender)} alt="user avatar" className="nav-avatar" />}
          <div className="">
            <h3 className="nav-user-name">{name}</h3>
            <button className="nav-logout-btn" onClick={() => onLogout(ROUTES.LOGIN)}>
              <IoIosLogOut className="nav-logout-icon" />
              {isIpadBreakpoint && i18n.t('settings.logout')}
            </button>
          </div>
        </div>
      </nav>
      {alertLeave && (
        <AlertModal
          modalIsOpen={alertLeave}
          closeModal={setIsAlertLeave}
          onConfirm={handleConfirm}
          title={i18n.t('circuit.alert.title')}
          description={i18n.t('circuit.alert.message')}
        />
      )}
    </>
  );
};

export default Nav;
