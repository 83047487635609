import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { markAsCompleted } from 'store/actions/patient';
import { KEY_USER } from 'config/constants';

import Circuit from './Circuit';

const ContainerPlan = () => {
  const authData = JSON.parse(localStorage.getItem(KEY_USER) || '{}');
  const { token = '' } = { ...authData };

  const dispatch = useDispatch();
  const { plan = {} } = useSelector((state) => {
    const { patient = {} } = { ...state };
    return { ...patient };
  });

  const { id: planId } = { ...plan };

  const onCompleteDay = async (timestamp, callback) => {
    await dispatch(markAsCompleted({ token, planId, startAt: timestamp ? `${timestamp}` : undefined }));
    !!callback && callback();
  };

  return <Circuit plan={plan} onCompleteDay={onCompleteDay} />;
};

export default ContainerPlan;
