import React from 'react';

const SelectInput = ({ name, label, touched, errors, options, ...rest }) => {
  return (
    <div className={'uk-flex uk-flex-column uk-margin-bottom uk-position-relative'}>
      <label htmlFor={name} className={'uk-text-muted uk-text-small uk-margin-small-bottom'}>
        {label}
      </label>
      <select
        className={'uk-select'}
        name={name} 
        id={name}
        {...rest}
      >
        <option hidden value=''></option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
      {touched[name] && errors[name] && (
        <p role={'alert'} className={'login-alert uk-text-danger uk-text-small'}>
          {errors[name]}
        </p>
      )}
    </div>
  );
}

export default SelectInput;
