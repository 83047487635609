import React from 'react';
import { useSelector } from 'react-redux';

import Profile from './Profile';

const ContainerProfile = () => {

  const { plan = {} } = useSelector((state) => {
    const { patient = {} } = { ...state };
    return { ...patient };
  });

  return (
    <Profile plan={plan}/>
  );
};

export default ContainerProfile;