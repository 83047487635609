import types from '../types/ui';

const init = {
  loading: false,
};

const reducer = (state = init, action) => {
  const { payload = null } = { ...action };
  switch (action.type) {
    case types.SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
};

export default reducer;
