import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { KEY_USER } from 'config/constants';

const PrivateRoute = ({ component: Component, ...rest }) => {
  // TODO: IMPLEMENT THIS VALIDATION
  const loggedIn = !!localStorage.getItem(KEY_USER);
  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
              search: props.location.search,
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
