export const GET_RECOMMENDED_PROGRAMS = `query {
  recommendedPrograms {
      id
      title
      description
      doctorName
      backgroundImage {
          original
          thumb
      }
      doctorImage {
          thumb
      }
      duration 
  }
}`;

export const ENROLL_TO_PROGRAM = `mutation enrollToProgram($programId: String!, $reminder:ReminderInput) {
  enrollToProgram(programId: $programId, reminder: $reminder) {
      errors
      success
  }
}`;

export const GET_EMPLOYEE_PROGRAMS = `query {
  employeePrograms  {
      id
      updatedAt
      createdAt
      currentPlanDay
      currentAudioDay
      currentProgramDay
      currentReadingDay
      status
      readingsDay {
          backgroundImage {
              original
              thumb
          }
          content
          id
          title
      }
      planDay {
          dayExercises {
              exercisePosition
              id
              rules
          }
          exercises {
              id
              image
              bodyParts
              equipments
              instructions
              levels
              reps
              rest
              sets
              tips
              video
              videoUrl
              title
              duration
          }
          id
          planNumber
          weekNumber
          weekTitle
      }
      program {
          id
          title
          description
          doctorName
          backgroundImage {
              original
              thumb
          }
          doctorImage {
              thumb
          }
          duration 
          readingTemplate {
              id
              backgroundImage {
                  original
                  thumb
              }
              title
          }
          planTemplate {
              title
              image
              numberOfExercises
          }
      }
  }
}`;

export const UPDATE_CURRENT_PROGRAM_DAY = `mutation updateCurrentProgramDay ($patientProgramId: String!, $sessionStartAt:String, $programType:String!) {
  updateCurrentProgramDay(patientProgramId: $patientProgramId, sessionStartAt: $sessionStartAt, programType: $programType) {
      errors
      success
  }
}`;

export const UNACTIVE_PROGRAM = `mutation unactivePatientProgram ($patientProgramId: String!) {
  unactivePatientProgram(patientProgramId: $patientProgramId) {
      errors
      success
  }
}`;

export const SET_REMINDER = `mutation setDefaultReminder ($reminder:ReminderInput) {
  setDefaultReminder(reminder: $reminder) {
      errors
      success
  }
}`;
