import React from 'react';

const Icon = (props) => {
  const { iconType = '', iconRatio = '', color = '', clickable = false, onClick = () => {}, className = '' } = { ...props };

  return (
    <span 
      onClick={onClick} 
      className={`${className} ${clickable && 'icon-clickable'}`} 
      style={{ margin: '0 6px 4px 0', ...(!!color && { color })}} 
      uk-icon={`icon: ${iconType}; ratio: ${iconRatio}`}
    >
    </span>
  );
};

export default Icon;