import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'utils/constants';

import './recommended-programs.css';

const RecommendedPrograms = () => {
  const { push } = useHistory();
  const { recommendedPrograms = [] } = useSelector((state) => {
    const { programs = {} } = state;
    return programs;
  });

  return (
    <ul className="recommended-programs-container">
      {recommendedPrograms.map((program) => {
        return (
          <button
            key={program.id}
            onClick={() => {
              push(`${ROUTES.ENROLL_PROGRAM}?id=${program.id}`, { program });
            }}>
            <li className="recommended-programs-program">
              <img className="recommended-programs-program-image" src={program.backgroundImage.thumb} alt={program.title} />
              <div className="recommended-programs-program-title-container">
                <h3 className="recommended-programs-program-title">{program.title}</h3>
              </div>
            </li>
          </button>
        );
      })}
    </ul>
  );
};

export default RecommendedPrograms;
