import React from 'react';
import i18n from 'config/i18n';

import './program-lectures.css';

const ProgramLectures = ({ employeeProgram }) => {
  const readings = employeeProgram?.readingsDay;
  return (
    <section className="program-lectures-container">
      <h3 className="program-lectures-title">{i18n.t('enrollmentProgram.lectures')}</h3>
      <ul className="program-lectures-list">
        {(readings || []).map((reading) => (
          <li key={reading.id} className="program-lectures-lecture">
            <img src={reading.backgroundImage.thumb} alt={reading.title} className="program-lectures-img" />
            <h4 className="program-lectures-lecture-title">{reading.title}</h4>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default ProgramLectures;
