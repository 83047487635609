import React, { useState } from 'react';
import Icon from 'components/Icon';

const widthModifiers = {
  large: 'uk-form-width-large',
  medium: 'uk-form-width-medium',
  small: 'uk-form-width-small',
  xSmall: 'uk-form-width-xsmall',
  setWidth: 'uk-width-1-1',
};

const Input = ({ label, type, name, widthModifier, errors = {}, touched = {}, ...rest }) => {

  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => setShowPassword(prevState => !prevState);

  return (
    <div className={'uk-flex uk-flex-column uk-margin-bottom uk-position-relative'}>
      <label htmlFor={name} className={'uk-text-muted uk-text-small uk-margin-small-bottom'}>
        {label}
      </label>
      <input
        className={`uk-input ${widthModifiers[widthModifier] || ''}`}
        id={name}
        name={name}
        type={showPassword ? 'text' : type}
        {...rest}
      />
      {touched[name] && errors[name] && (
        <p role={'alert'} className={'login-alert uk-text-danger uk-text-small'}>
          {errors[name]}
        </p>
      )}
      {(type === 'password' && !!rest.onBlur) && (
        <Icon
          iconType={showPassword ? 'unlock' : 'lock'}
          iconRatio={'0.9'}
          onClick={togglePassword}
          className={'uk-position-absolute uk-position-bottom-right uk-margin-small-bottom'}
          clickable
        />
      )}
    </div>
  );
}

export default Input;