const types = {
  SET_USER: '@auth/SET_USER',
  SET_ERROR: '@auth/SET_ERROR',
  SET_UPDATED: '@auth/SET_UPDATED',
  SET_SENT: '@auth/SET_SENT',
  LOGIN: '@auth/LOGIN',
  LOGIN_SURA: '@auth/LOGIN_SURA',
  RESET: '@auth/RESET',
  LOGOUT: '@auth/LOGOUT',
  LOGOUT_REQUEST: '@auth/LOGOUT_REQUEST',
  CHANGE_PASSWORD: '@auth/CHANGE_PASSWORD',
  SEND_FEEDBACK: '@auth/SEND_FEEDBACK',
};

export default types;
