import { useEffect } from 'react';
import ReactDOM from 'react-dom';

const ModalPortal = (props) => {
  const modalRoot = document.createElement('div');
  modalRoot.setAttribute('uk-modal', `bg-close: ${props.closable}`);
  modalRoot.id = props.modalId;

  useEffect(() => {
    document.body.appendChild(modalRoot);
    return () => {
      document.body.removeChild(modalRoot);
    }
  });

  return ReactDOM.createPortal(props.children, modalRoot);
} 

export default ModalPortal;