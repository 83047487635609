import React from 'react';
import { useSelector } from 'react-redux';

import PlanCard from 'components/PlanCard';
import ProgramCard from 'components/ProgramCard';

import './exercises.css';

const Exercises = ({ isEmployee }) => {
  const { plan = {}, programs = {} } = useSelector((state) => {
    const { patient = {}, programs = {} } = state;
    const { plan = {} } = patient;
    return { plan, programs };
  });

  const { patient = {} } = plan;
  const { patientPlans = [] } = patient;
  const { employeePrograms = [] } = programs;
  const userExercises = [...patientPlans, ...employeePrograms];
  if (!isEmployee) {
    return (
      <ul className="exercises-container">
        <PlanCard exercise={plan} />
      </ul>
    );
  }

  return (
    <ul className="exercises-container">
      {userExercises.map((userExercise) => {
        const { id, plan } = userExercise;
        if (plan) {
          return <PlanCard key={id} exercise={userExercise} />;
        }
        return <ProgramCard key={id} exercise={userExercise} />;
      })}
    </ul>
  );
};

export default Exercises;
