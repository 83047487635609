import i18n from 'config/i18n';
import UIkit from 'uikit';

const position = {
  topLeft: 'top-left',
  topCenter: 'top-center',
  topRight: 'top-right',
  bottomLeft: 'bottom-left',
  bottomCenter: 'bottom-center',
  bottomRight: 'bottom-right',
};

const style = {
  primary: 'primary',
  success: 'success',
  warning: 'warning',
  danger: 'danger',
};

const message = {
  success: i18n.t('settings.modal.success.subtitle'),
  error: i18n.t('settings.modal.error.subtitle'),
  unready: `${i18n.t('calls.tokenErrorTitle')} ${i18n.t('calls.tokenErrorSubtitle')}`,
  errorUpdateCall: `${i18n.t('calls.errorTitle')} ${i18n.t('calls.errorUpdateTitle')}`,
  errorRequestCall: `${i18n.t('calls.errorTitle')} ${i18n.t('calls.errorSubtitle')}`,
  invalidFields: i18n.t('login.invalidFields'),
  loginSuccess: i18n.t('login.loginSuccess'),
  loginError: i18n.t('login.loginError'),
};

export const showNotification = (typeMessage, statusType, notiPosition = 'topRight') => {
  UIkit.notification({message: `${message[typeMessage]}`, status: `${style[statusType]}`, pos: `${position[notiPosition]}`})
};