import I18n from 'i18n-js';

import en from './en';
import es from './es';

// TODO: Implement language detection and switch
I18n.locale = 'es';

I18n.fallbacks = true;
I18n.translations = { en, es };

export default I18n;
