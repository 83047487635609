export const ROUTES = {
  LOGIN: '/',
  LOGIN_SURA: '/login-sura',
  DASHBOARD: '/dashboard',
  HOME: '/home',
  PLAN: '/plan',
  PROGRAM: '/program',
  ENROLL_PROGRAM: '/enroll-program',
  LECTURES: '/lectures',
  CIRCUIT: '/circuit',
  NEW_CIRCUIT: '/new-circuit',
  FEEDBACK: '/feedback/',
  SETTINGS: '/settings',
  NOTIFICATIONS: '/notifications',
  CONTACT: '/contact',
  CALL: '/call',
};

export const DNI_TYPES = [
  { value: 'C', label: 'CÉDULA' },
  { value: 'E', label: 'CÉDULA EXTRANJERIA' },
  { value: 'D', label: 'DIPLOMÁTICO' },
  { value: 'X', label: 'DOC.IDENT. DE EXTRANJEROS' },
  { value: 'F', label: 'IDENT. FISCAL PARA EXT.' },
  { value: 'A', label: 'NIT' },
  { value: 'CA', label: 'NIT PERSONAS NATURALES' },
  { value: 'N', label: 'NUIP' },
  { value: 'P', label: 'PASAPORTE' },
  { value: 'R', label: 'REGISTRO CIVIL' },
  { value: 'T', label: 'TARJ. IDENTIDAD' },
  { value: 'TC', label: 'CERTIFICADO NACIDO VIVO' },
  { value: 'TP', label: 'PASAPORTE ONU' },
  { value: 'TE', label: 'PERMISO ESPECIAL DE PERMANENCIA' },
  { value: 'TS', label: 'SALVOCONDUCTO DE PERMANENCIA' },
  { value: 'TF', label: 'PERMISO ESPECIAL FORMACN PEPFF' },
];
