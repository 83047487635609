import React from 'react';
import i18n from 'config/i18n';
import { isEmpty } from 'ramda';
import { MdClose } from 'react-icons/md';

import Modal from 'components/ReactModal';

const ExerciseGuide = ({ modalIsOpen, closeModal, exercise }) => {
  const instructions = exercise?.instructions.split('- ').filter((el) => el.length > 1);
  const tips = exercise?.tips.split('- ').filter((el) => !!el);
  return (
    <Modal modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <section className="new-circuit-exercise-instructions">
        <MdClose className="exercise-guide-close-btn" onClick={closeModal} />
        <div>
          <h3 className="new-circuit-exercise-instructions-title">{i18n.t('exercise.instructions')}</h3>
          <ol className="new-circuit-exercise-instructions-list">
            {(instructions || []).map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ol>
        </div>
        {!isEmpty(tips) && (
          <div>
            <h3 className="new-circuit-exercise-instructions-title">{i18n.t('exercise.tips')}</h3>
            <ol className="new-circuit-exercise-instructions-list">
              {(tips || []).map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ol>
          </div>
        )}
      </section>
    </Modal>
  );
};

export default ExerciseGuide;
