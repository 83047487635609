import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18n from 'config/i18n';

import { getPatientInfo } from 'store/actions/patient';
import { getRecommendedPrograms, getEmployeePrograms } from 'store/actions/programs';

import Exercises from 'components/Exercises';
import RecommendedPrograms from 'components/RecommendedPrograms';
import Nav from 'components/Nav';

import './home.css';

const GREETINGS = {
  MORNING: 'morning',
  AFTERNOON: 'afternoon',
  NIGHT: 'night',
};

const Home = () => {
  const { location = {} } = useHistory();
  const { state = {} } = location;
  const refetch = state?.refetch;

  const dispatch = useDispatch();
  const { user = {} } = useSelector((state) => {
    const { auth = {} } = state;
    return auth;
  });
  const { name = '', patientType = '', token } = user;
  const isEmployee = patientType === 'Employee';

  const refetchUserInfo = () => {
    dispatch(getPatientInfo({ token, patientType }));
    if (isEmployee) {
      dispatch(getRecommendedPrograms({ token }));
      dispatch(getEmployeePrograms({ token }));
    }
  };

  useEffect(() => {
    if (refetch) {
      refetchUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const hours = new Date().getHours();
  const getGreeting = (dayHours) => {
    if (dayHours < 12) {
      return GREETINGS.MORNING;
    }
    if (dayHours > 11 && dayHours < 18) {
      return GREETINGS.AFTERNOON;
    }
    return GREETINGS.NIGHT;
  };

  return (
    <>
      <Nav />
      <section className="home-container">
        <header className="home-header">
          <h1 className="home-header-greet">
            {i18n.t(`home.${getGreeting(hours)}`)} <br />
            <span className="home-header-name">{name}</span>
          </h1>
        </header>
        <section className="home-exercises">
          <h2 className="home-exercises-title">{i18n.t('home.exercises')}</h2>
          <Exercises isEmployee={isEmployee} />
          <div className="divider" />
          {isEmployee && (
            <>
              <h2 className="home-exercises-title">{i18n.t('home.recommended')}</h2>
              <RecommendedPrograms />
            </>
          )}
        </section>
      </section>
    </>
  );
};

export default Home;
