import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import i18n from 'config/i18n';
import { ROUTES, DNI_TYPES } from 'utils/constants';

import Button from 'components/Button';
import Input from 'components/Input';
import SelectInput from 'components/SelectInput';

import LogoSura from 'assets/images/logo-sura.png';


const LoginSura = (props) => {

  const {
    onLogin = () => {},
    handleChange = () => {},
    handleBlur = () => {},
    touched = {},
    loginSuraCredentials = {},
    errors = {},
    patient = {},
    token = '',
  } = { ...props };
  const { type = '', dni = '', password = '' } = { ...loginSuraCredentials };
  const { plan = {} } = { ...patient };
  const { id: planId = '' } = { ...plan };

  const history = useHistory();

  useEffect(() => {
    if (!!planId) {
      history.push(ROUTES.PLAN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planId]);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    onLogin({ type, dni, password });
  };

  if (token) return null;

  return (
    <div style={{ maxWidth: 300 }} className={'uk-position-center'}>
      <div className={'uk-flex uk-flex-center'}>
        <img src={LogoSura}  alt={'logo Sura'}/>
      </div>
      <p className={'uk-text-center uk-text-small uk-margin-small-top'}>{i18n.t('login.introSura')}</p>
      <form onSubmit={handleSubmit}>
        <SelectInput
          options={DNI_TYPES}
          label={i18n.t('login.type')}
          name={'type'}
          value={type}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors}
          touched={touched}
        />
        <Input
          label={i18n.t('login.dni')}
          type={'number'}
          name={'dni'}
          value={dni}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors}
          touched={touched}
        />
        <Input
          label={i18n.t('login.password')}
          type={'password'}
          name={'password'}
          value={password}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors}
          touched={touched}
        />
        <div className={'uk-flex uk-flex-center uk-margin-medium-top'}>
          <Button type={'submit'} primary>
            {i18n.t('login.login')}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default LoginSura;