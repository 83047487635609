import React from 'react';
import { useHistory } from 'react-router-dom';
import i18n from 'config/i18n';
import renderHTML from 'react-render-html';
import { IoIosArrowBack } from 'react-icons/io';

import { queryParams } from 'utils/string';
import { useToggle } from 'utils/hooks';
import { ROUTES } from 'utils/constants';

import Nav from 'components/Nav';
import EnrollProgramModal from 'components/EnrollProgramModal';
import EnrollSuccessModal from 'components/EnrollSuccessModal';

import './enroll-program.css';

const EnrollProgram = () => {
  const {
    replace,
    location: {
      search,
      state: { program = {} },
    },
  } = useHistory();
  const { id: programId } = queryParams(search);

  const { backgroundImage = {}, doctorImage = {}, title = '', description = '', duration = 30 } = program;
  const { thumb = '' } = doctorImage;
  const { original = '' } = backgroundImage;

  const [enrollModal, setEnrollModal] = useToggle();
  const [successModal, setSuccessModal] = useToggle();

  const handleGoBack = () => {
    replace(ROUTES.HOME);
  };

  if (!programId) {
    return null;
  }
  return (
    <>
      <Nav />
      <section className="program-container">
        <div className="program-background" style={{ backgroundImage: `url(${original})` }} />
        <section className="program-content">
          <header className="program-header">
            <IoIosArrowBack className="program-header-back" onClick={handleGoBack} />
            <img className="program-card-image" src={thumb} alt={title} />
            <div className="program-header-right-side">
              <h3 className="program-header-title">{title}</h3>
              <span className="program-card-time">{i18n.t('programs.enrolled.session', { duration })}</span>
            </div>
          </header>
          <div className="program-divider" />
          <section className="program-description">{renderHTML(description)}</section>
          <div className="program-enroll">
            <button className="enroll-button" onClick={setEnrollModal}>
              {i18n.t('programs.enroll.btn')}
            </button>
          </div>
        </section>
      </section>
      {enrollModal && (
        <EnrollProgramModal
          modalIsOpen={enrollModal}
          closeModal={setEnrollModal}
          toggleSuccessModal={setSuccessModal}
          programId={programId}
        />
      )}
      {successModal && <EnrollSuccessModal modalIsOpen={successModal} closeModal={setSuccessModal} />}
    </>
  );
};

export default EnrollProgram;
