const es = {
  onboarding: {
    skip: 'Saltar',
    next: 'Siguiente',
    slide1: 'Tu plan personalizado\nsiempre contigo',
    slide2: 'Realiza tus ejercicios de\nrehabilitación en cualquier lugar',
    slide3: 'Siempre conectado con tu especialista\npara retroalimentación y sugerencias',
  },
  login: {
    intro: 'Ingresa para obtener acceso a tu plan de entrenamiento otorgado por tu terapeuta.',
    introSura: 'Ingresa para obtener acceso a tu plan de entrenamiento y alimentación.',
    login: 'Ingresar',
    loginPartners: 'Ingresar con Sura',
    forgot: '¿Olvidaste tu contraseña?',
    forgotIntro: 'Confirma tu correo electrónico, te enviaremos un link para cambiar tu contraseña',
    forgotButton: 'Recuperar contraseña',
    forgotSuccess: 'El correo de recuperación ha sido enviado exitosamente',
    forgotError: 'No fue posible enviar el correo de recuperación, intente nuevamente',
    disclaimer: 'Al hacer clic en Ingresar, acepta nuestras',
    terms: 'Condiciones',
    policies: 'Política de Privacidad.',
    and: 'y',
    email: 'Correo electrónico',
    dni: 'Número de identificación',
    type: 'Tipo de identificación',
    password: 'Password',
    requiredEmail: 'El Correo electrónico es requerido',
    requiredPassword: 'La contraseña es requerida',
    requiredType: 'El tipo de documento es requerido',
    requiredDni: 'El número de documento es requerido',
    emailError: '* Es necesario ingresar un correo válido',
    passwordError: '* Es necesario ingresar una contraseña de mínimo 6 caracteres',
    urlPolicy: 'https://web.healthphy.co/privacidad',
    urlTerms: 'https://web.healthphy.co/terminos',
    //loginSuccess: 'Bienvenido',
    loginError: 'Usuario y contraseña inválidos, intenta de nuevo',
    invalidFields: 'Ingresa correctamente los campos requeridos',
    inactiveTitle: 'Cuenta inactiva',
    inactiveMessage:
      'Tu especialista a desactivado tu cuenta, ponte en contacto con él o escríbenos a info@healthphy.de para asignarte uno.',
    inactiveOk: 'Aceptar',
  },
  tabs: {
    today: 'Hoy',
    settings: 'Perfil',
    tips: 'Tips',
    contact: 'Contacto',
  },
  home: {
    today: 'Hoy',
    morning: 'Buenos días,',
    afternoon: 'Buenas Tardes,',
    night: 'Buenas Noches,',
    exercises: 'Ejercicios',
    time: '{{time}} Minutos',
    attends: 'Te atiende:',
    planEnd: 'Espera, tu médico actualizará tu plan de ejercicios',
    planMissing: 'Comunícate con tu médico para crear tu plan de ejercicios',
    call: 'Llamada pendiente',
    survey: 'Encuesta pendiente',
    callTitle: 'Bienvenid{{value}} a HealthPhy',
    callMessage: 'Agenda una llamada con tu especialista para iniciar tu terapia',
    event: 'Evento próximo',
    parts: 'Partes del cuerpo relacionadas',
    recommended: 'Programas recomendados',
  },
  forceUpdate: {
    title: 'Nueva versión disponible',
    message: 'Hay una nueva versión disponible en tiendas. Es necesario que actualices el app para continuar.',
    button: 'Actualizar',
  },
  settings: {
    title: 'Perfil',
    settings: 'Ajustes',
    myData: 'Mis datos',
    myDataSub: 'Edita tus datos personales',
    clinic: 'Especialista',
    name: 'Nombre',
    description: 'Descripción',
    email: 'Correo electrónico',
    dni: 'Documento de identidad',
    birthday: 'Fecha de nacimiento',
    gender: 'Género',
    policies: 'Política de privacidad',
    terms: 'Términos y condiciones',
    opinion: {
      title: 'Danos tu opinión',
      message: '¡Nos encantaría saber tu opinión!',
      button: 'Enviar',
      successTitle: 'Gracias!',
      successMessage: 'Tus comentarios fueron enviados exitosamente',
      errorMessage: 'No se han podido enviar tus comentarios, intentelo nuevamente.',
    },
    logout: 'CERRAR SESIÓN',
    changePassword: 'Cambiar contraseña',
    saveChanges: 'Guardar cambios',
    password: {
      showPassword: 'Mostrar contraseña',
      password: 'Contraseña',
      confirm: 'Confirmar contraseña',
      wMatch: 'Las contraseñas deben coincidir',
      wUpper: 'Al menos una letra mayúscula (A-Z)',
      wLower: 'Al menos una letra minúscula (a-z)',
      wNumber: 'Al menos un número (0-9)',
      wSpecial: 'Al menos un carácter especial',
      minLength: 'Al menos 8 caracteres',
    },
    modal: {
      success: {
        title: 'Guardado correctamente!',
        subtitle: 'La contraseña se ha actualizado exitosamente.',
        button: 'Aceptar',
      },
      error: {
        title: 'Lo sentimos!',
        subtitle: 'No se ha podido actualizar la contraseña, intentelo nuevamente.',
        button: 'Aceptar',
      },
    },
    version: 'Versión {{version}}',
  },
  notAvailable: {
    title: 'Sitio en construcción',
    subtitle: 'Realiza tus ejercicios de\nrehabilitación en cualquier lugar',
  },
  dayDetail: {
    title: 'Ejercicios para Hoy',
    time: '{{time}} Minutos',
    exercises: '{{exercises}} Ejercicios',
    equipments: 'Objetos requeridos:',
    exData: '{{sets}} series · {{reps}} repeticiones',
    weekTitle: 'Día {{day}} de {{week}}',
    button: 'Completar día',
    alert: {
      title: 'Marcar como completado',
      message: '¿Desea marcar como completado el ejercicio del día de hoy?',
      ok: 'Aceptar',
      cancel: 'Cancelar',
    },
    completedError: 'No fue posible marcar el día como completado, intentalo nuevamente',
    completedTitle: '¡Felicidades!',
    completedMessage: 'Has completado tu sesión de ejercicios del día',
    finishedMessage: 'Has terminado tu plan de ejercicios con éxito',
    completedButton: 'Aceptar',
    start: 'Empezar ejercicios',
    home: 'Ir a inicio',
  },
  circuit: {
    alert: {
      title: 'Abandonar sesión',
      message: '¿Realmente desea abandonar la sesión del día de hoy?',
      ok: 'Aceptar',
      cancel: 'Cancelar',
    },
  },
  exercise: {
    title: 'Detalle del Ejercicio',
    play: 'Reproducir',
    reps: 'Repeticiones',
    sets: 'Series',
    instructions: 'Instrucciones',
    rest: 'Descanso',
    tips: 'Consejos',
    requiredObjects: 'Objetos requeridos',
  },
  calls: {
    title: 'Contacto',
    greet:
      'Hola mi nombre es {{clinicName}} y te estaré atendiendo de ahora en adelante en tu proceso fisioterapeutico, agenda una llamada y te atenderé dudas sobre tu programa de ejercicios.',
    connecting: 'Conectando...',
    connMessage: 'Por favor espera mientras tu médico tratante se conecta a la videollamada',
    schedule: 'Agendar',
    requestTitle: 'Agenda una llamada',
    doctor: 'Escoge una fecha y hora disponible para agenda la llamada con tu especialista',
    date: 'Fecha',
    time: 'Hora',
    change: 'Cambiar',
    scheduleCall: 'Agendar llamada',
    message: 'Mensaje',
    errorTitle: 'Lo sentimos!',
    errorSubtitle: 'No se ha podido agendar la llamada, intentelo nuevamente.',
    errorUpdateTitle: 'No se ha podido actualizar la solicitud, intentelo nuevamente.',
    errorButton: 'Aceptar',
    tokenErrorTitle: 'Lo sentimos!',
    tokenErrorSubtitle: 'La llamada aún no esta disponible, revisa la hora agendada',
    tokenErrorButton: 'Aceptar',
    dateButton: 'Cerrar',
    notAvailability: 'No hay disponibilidad en este día',
    notAvailabilityMsg: 'Tu especialista no tiene agenda disponible para el día seleccionado',
    bookingMessage: 'Me gustaría agendar una llamada para el ',
    acceptMessage: 'Su llamada fue ',
    acceptMessageMed: 'aceptada',
    acceptMessageEnd: ' por tu especialista para el ',
    rejectedMessage: 'Su llamada fue ',
    rejectedMessageMed: 'rechazada',
    rejectedMessageEnd: ' por tu especialista',
    cancel: 'Cancelar',
    reschedule: 'Reagendar',
    enter: 'Unirse a la llamada',
    of: 'de',
    at: 'a las',
    cancelModal: {
      title: 'Cancelar llamada',
      message: '¿Realmente desea cancelar la solicitud de llamada?',
      ok: 'OK',
      cancel: 'Atras',
    },
  },
  notifications: {
    title: 'Notificaciones',
    description: 'Configurar permisos',
    on: 'ON',
    off: 'OFF',
    empty: 'Actualmente no tienes notificaciones en tu bandeja',
  },
  feedback: {
    title: '¿Qué tal tu día de ejercicios?',
    skip: 'Saltar',
    description: 'Nos gustaría saber tu opinión para mejorar en tus siguientes sesiones.',
    likeTitle: '¿Te gustó la sesión de hoy?',
    difficulty0: 'Sin Dolor',
    difficulty1: 'Muy Leve',
    difficulty2: 'Leve',
    difficulty3: 'Moderado',
    difficulty4: 'Intenso',
    difficulty5: 'Insoportable',
    difficultyTitle: '¿Cual fue la intensidad de dolor al realizar los ejercicios?',
    commentsTitle: '¿Algún comentario?',
    send: 'Enviar',
  },
  pain: {
    title: 'Queremos saber como te sientes hoy',
    subtitle: 'Cuéntanos ¿cual es la intensidad de tu dolor?',
    send: 'Enviar',
    level0: 'Sin Dolor',
    level2: 'Muy Leve',
    level4: 'Leve',
    level6: 'Moderado',
    level8: 'Intenso',
    level10: 'Insoportable',
  },
  menu: {
    dashboard: 'Inicio',
    myplan: 'Mi plan',
    today: 'Ejercicios para hoy',
    contact: 'Contacto',
    settings: 'Configuración',
    notifications: 'Notificaciones',
  },
  programs: {
    sessionTitle: 'sesiones',
    lectures: {
      nextBtn: 'Siguiente lectura',
      endBtn: 'Finalizar día',
      alert: {
        message: '¿Quieres marcar las lecturas como finalizadas el día de hoy?',
      },
    },
    enroll: {
      btn: 'Enrolarse al programa',
      modalTitle: 'Crea tu recordatorio',
      modalSubTitle: 'Crea el hábito para mejorar tu salud.',
      time: '¿A qué hora?',
      repeat: 'Repetición',
      chooseDays: 'Selecciona en cuales días te gustaria recibir recordatorios.',
      start: 'Empezar',
    },
    enrolled: {
      sessions: '{{day}} sesiones de {{duration}}',
      session: 'Sesiones {{duration}}',
    },
    unenroll: {
      confirmTitle: '¿Está seguro?',
      confirmMessage: 'Terminar el programa antes y eliminar todas las próximas sesiones.',
      finishBtn: 'Finalizar',
      unactiveButton: 'Finalizar programa',
    },
    dayDisable: 'Día no habilitado',
    dayDisableBody: 'Al dia que intentas acceder aún no está habilitado',
    endTitle: '¡Felicidades!',
    endMessage: 'Has finalizado con éxito el programa.',
  },
  enrollmentProgram: {
    activities: 'Actividades',
    description: 'Descripción',
    lectures: 'Lecturas',
    lecture: 'Lectura',
    audio: 'Audios',
    exercises: 'Ejercicios',
    exercise: 'Ejercicio',
    completedLecturesMessage: 'Has completado tu sesión de lecturas del día',
    btn: {
      lectures: 'Empezar lecturas',
      audio: 'Empezar audios',
      exercises: 'Empezar ejercicios',
    },
    headerCurrentDay: 'día {{day}} de {{duration}}',
    completed: '¡Completado!',
    successEnroll: {
      title: '¡Te has registrado al programa!',
      caption: 'Ya puedes empezar tu programa enfocado en mejorar tu salud, bienestar y estilo de vida.',
      btn: 'Continuar',
    },
  },
};

export default es;
