import types from '../types/patient';

export const setError = (payload) => ({ type: types.SET_ERROR, payload });
export const setCompleted = (payload) => ({ type: types.SET_COMPLETED, payload });
export const setFinished = (payload) => ({ type: types.SET_FINISHED, payload });
export const getPatientInfo = (payload) => ({ type: types.GET_PATIENT_INFO, payload });
export const getEmployeeInfo = (payload) => ({ type: types.GET_EMPLOYEE_INFO, payload });
export const getPatientPlan = (payload) => ({ type: types.GET_PATIENT_PLAN, payload });
export const setPatientPlan = (payload) => ({ type: types.SET_PATIENT_PLAN, payload });
export const setPatientInfo = (payload) => ({ type: types.SET_PATIENT_INFO, payload });
export const markAsCompleted = (payload) => ({ type: types.MARK_AS_COMPLETED, payload });
export const sendFeedback = (payload) => ({ type: types.SEND_FEEDBACK, payload });
export const sendPainLevel = (payload) => ({ type: types.SEND_PAIN_LEVEL, payload });
export const markAsRead = (payload) => ({ type: types.MARK_AS_READ, payload });
