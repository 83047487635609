import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import i18n from 'config/i18n';
import renderHTML from 'react-render-html';
import moment from 'moment';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { IoIosArrowBack } from 'react-icons/io';

import { queryParams } from 'utils/string';
import { useToggle } from 'utils/hooks';
import { ROUTES } from 'utils/constants';

import DescriptionModal from 'components/DescriptionModal';
import ProgramActivities from 'components/ProgramActivities';
import ProgramLectures from 'components/ProgramLectures';
import ProgramExercises from 'components/ProgramExercises';
import Nav from 'components/Nav';

import './program.css';

export const PROGRAM_VIEWS = {
  ACTIVITIES: 'activities',
  LECTURES: 'lectures',
  EXERCISES: 'exercises',
};

export const BTN_LABEL = {
  [PROGRAM_VIEWS.LECTURES]: i18n.t('enrollmentProgram.btn.lectures'),
  [PROGRAM_VIEWS.EXERCISES]: i18n.t('enrollmentProgram.btn.exercises'),
};

const Program = () => {
  const {
    replace,
    push,
    location: { search },
  } = useHistory();
  const { id: programId } = queryParams(search);

  const { employeePrograms = [] } = useSelector((state) => {
    const { programs = {} } = state;
    return programs;
  });
  const employeeProgram = employeePrograms.find((employeeProgram) => employeeProgram.id === programId);
  const original = employeeProgram?.program?.backgroundImage?.original;
  const thumb = employeeProgram?.program?.doctorImage?.thumb;
  const title = employeeProgram?.program?.title;
  const description = employeeProgram?.program?.description;
  const duration = employeeProgram?.program?.duration;
  const readingDay = employeeProgram?.currentReadingDay;
  const planDay = employeeProgram?.currentPlanDay;
  const readingsDay = employeeProgram?.readingsDay;
  const isRedirect = readingDay !== planDay || readingsDay?.length === 0;
  const textChunk = description ? description.split('</p>')[0].split('<p>')[1].split('</span>')[0].slice(0, -2) + '... ' : '...';

  const [programView, setProgramView] = useState(PROGRAM_VIEWS.ACTIVITIES);
  const [descriptionModal, setDescriptionModal] = useToggle();

  const handleGoBack = () => {
    if (programView === PROGRAM_VIEWS.ACTIVITIES) {
      replace(ROUTES.HOME);
    } else {
      setProgramView(PROGRAM_VIEWS.ACTIVITIES);
    }
  };

  const timestamp = moment().unix();
  const handleStartActivity = () => {
    if (programView === PROGRAM_VIEWS.LECTURES) {
      push(`${ROUTES.LECTURES}?program_id=${programId}`, { timestamp, redirect: isRedirect });
    } else {
      push(`${ROUTES.NEW_CIRCUIT}?program_id=${programId}`, { timestamp, redirect: isRedirect });
    }
  };

  if (!programId) {
    return null;
  }

  return (
    <>
      <Nav />
      <section className="program-container">
        <div className="program-background" style={{ backgroundImage: `url(${original})` }} />
        <section className="program-content">
          <header className="program-header">
            <IoIosArrowBack className="program-header-back" onClick={handleGoBack} />
            <img className="program-card-image" src={thumb} alt={title} />
            <div className="program-header-right-side">
              <h3 className="program-header-title">{title}</h3>
              <span className="program-card-time">{i18n.t('programs.enrolled.session', { duration })}</span>
            </div>
          </header>
          <section>
            <div className="program-divider" />
            <div className="program-desc">
              <h3 className="program-desc-title">{i18n.t('enrollmentProgram.description')}</h3>
              <p className="program-desc-content">
                {renderHTML(textChunk)}
                <button onClick={setDescriptionModal}>
                  Ver más <AiOutlineExclamationCircle />
                </button>
              </p>
            </div>
            <div className="program-divider-2" />
            <div className="program-view">
              {programView === PROGRAM_VIEWS.ACTIVITIES && (
                <ProgramActivities employeeProgram={employeeProgram} onProgramView={setProgramView} />
              )}
              {programView === PROGRAM_VIEWS.LECTURES && <ProgramLectures employeeProgram={employeeProgram} />}
              {programView === PROGRAM_VIEWS.EXERCISES && <ProgramExercises employeeProgram={employeeProgram} />}
            </div>
          </section>
          {programView !== PROGRAM_VIEWS.ACTIVITIES && (
            <>
              <div className="program-divider" />
              <footer className="program-start-activities-footer">
                <button className="program-start-activities-btn" onClick={handleStartActivity}>
                  {BTN_LABEL[programView]}
                </button>
              </footer>
            </>
          )}
        </section>
      </section>
      {descriptionModal && <DescriptionModal modalIsOpen={descriptionModal} closeModal={setDescriptionModal} description={description} />}
    </>
  );
};

export default Program;
