import React, { useState } from 'react';
import i18n from 'config/i18n';
import { MdClose } from 'react-icons/md';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import { enrollToProgram } from 'store/actions/programs';
import { handleScrollTo } from 'utils/handleScrollTo';

import Modal from 'components/ReactModal';

import './enroll-program-modal.css';

export const getCurrentLanguage = () => {
  let currentLocale = i18n.currentLocale().split('-')[0];
  const supportedLanguages = Object.keys(i18n.translations);
  if (!supportedLanguages.includes(currentLocale)) {
    currentLocale = 'en';
  }
  return currentLocale;
};

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontWeight: '300',
    fontSize: '14px',
    color: state.isSelected ? '#8093FC' : '#606060',
    backgroundColor: '#fff',
    ':hover': {
      color: '#fff',
      backgroundColor: '#8093FC',
      cursor: 'pointer',
    },
  }),
  container: (provided) => ({
    ...provided,
    width: '100%',
    maxWidth: '180px',
  }),
  control: (provided) => ({
    ...provided,
    padding: '4px',
    marginTop: '8px',
    borderRadius: '10px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '200px',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#606060',
    fontWeight: '300',
    fontSize: '14px',
  }),
};

const intervalOptions = [...Array(24)].map((_, index) => ({
  value: `0${index}:00`.slice(-5),
  label: `0${index}:00`.slice(-5),
}));

const EnrollProgramModal = ({ modalIsOpen, closeModal, toggleSuccessModal, programId }) => {
  const { user = {} } = useSelector((state) => {
    const { auth = {} } = state;
    return auth;
  });
  const { token } = user;
  const dispatch = useDispatch();

  const [selectedDays, setselectedDays] = useState([0, 1, 2, 3, 4, 5, 6]);
  const [hour, setHour] = useState('08:00');

  const handleSelectDay = (dayIndex) => {
    setselectedDays((prevDays) => {
      if (prevDays.includes(dayIndex)) {
        return prevDays.filter((day) => day !== dayIndex);
      }
      return [...prevDays, dayIndex];
    });
  };

  const handleAddHour = ({ value }) => {
    setHour(value);
  };

  const handleEnrollProgram = () => {
    handleScrollTo();
    dispatch(enrollToProgram({ token, programId, reminder: { time: hour, days: selectedDays } }));
    closeModal();
    toggleSuccessModal();
  };
  const weekDays =
    getCurrentLanguage() === 'en'
      ? ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
      : ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

  return (
    <Modal modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <section className="enroll-program-container">
        <MdClose className="enroll-program-close" onClick={closeModal} />
        <h2 className="enroll-program-title">{i18n.t('programs.enroll.modalTitle')}</h2>
        <p className="enroll-program-subtitle">{i18n.t('programs.enroll.modalSubTitle')}</p>
        <label>
          <span className=" enroll-program-time">{i18n.t('programs.enroll.time')}</span>
          <Select
            defaultValue={{ value: '08:00', label: '08:00' }}
            options={intervalOptions}
            styles={customStyles}
            onChange={handleAddHour}
          />
        </label>
        <h3 className="enroll-program-title-repeat">{i18n.t('programs.enroll.repeat')}</h3>
        <p className="enroll-program-subtitle">{i18n.t('programs.enroll.chooseDays')}</p>
        <ul className="enroll-program-days">
          {weekDays.map((day, index) => {
            const isSelected = [...selectedDays].indexOf(index) !== -1;
            return (
              <li key={day} className={`enroll-program-day ${isSelected ? 'enroll-program-selected-day' : ''}`}>
                <button onClick={() => handleSelectDay(index)}>{day.charAt()}</button>
              </li>
            );
          })}
        </ul>
        <button className="enroll-button enroll-program-btn" onClick={handleEnrollProgram}>
          {i18n.t('programs.enroll.start')}
        </button>
      </section>
    </Modal>
  );
};

export default EnrollProgramModal;
