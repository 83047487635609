export const capitalize = (text) =>
  `${text}`
    .trim()
    .toLowerCase()
    .replace(/^\w/, (c) => c.toUpperCase());

export const queryParams = (url) => {
  let hashes = url.slice(url.indexOf('?') + 1).split('&');
  return hashes.reduce((params, hash) => {
    let [key, val] = hash.split('=');
    return Object.assign(params, { [key]: decodeURIComponent(val) });
  }, {});
};    
