import { take, put, fork, all } from 'redux-saga/effects';
import { request } from 'graphql-request';
import { getAuthClient } from 'utils/graphql';
import types from 'store/types/auth';
import { setUser, setError, setSent, setUpdated } from 'store/actions/auth';
import { getPatientInfo } from 'store/actions/patient';
import { getRecommendedPrograms, getEmployeePrograms } from 'store/actions/programs';
import { showLoading, hideLoading } from 'store/actions/ui';
import { GRAPHQL_API, KEY_USER } from 'config/constants';
import { LOGIN, CHANGE_PASSWORD, /*FCM_TOKEN,*/ RESET_PASSWORD, LOGIN_SURA } from 'store/queries/auth';
// TODO: CHECK FIREBASE SDK utils
// import { firestore, messaging } from 'config/firebase';
import { logEvent, Event } from 'config/events';

function* login(username, password) {
  try {
    yield put(showLoading());
    const { signinUser: data = {} } = yield request(GRAPHQL_API, LOGIN, { username, password });
    const { token = null, clinic = null } = { ...data };
    if (!!token && !!clinic) {
      const user = { ...clinic, token };
      yield put(setUser(user));
      yield put(setError(false));
      localStorage.setItem(KEY_USER, JSON.stringify(user));
      logEvent(Event.LOGIN, { username, token });
    } else {
      yield put(setUser(null));
      yield put(setError(true));
    }
  } catch (error) {
    yield put(setError(true));
    console.log(error);
  } finally {
    yield put(hideLoading());
  }
}

function* loginSura(username, password) {
  try {
    yield put(showLoading());
    const { signinUser: data = {} } = yield request(GRAPHQL_API, LOGIN_SURA, { username, password, partner: 'sura' });
    const { token = null, clinic = null } = { ...data };
    if (!!token && !!clinic) {
      const user = { ...clinic, token };
      yield put(setUser(user));
      yield put(setError(false));
      yield localStorage.setItem(KEY_USER, JSON.stringify(user));
      logEvent(Event.LOGIN_SURA, { username, token });
    } else {
      yield put(setUser(null));
      yield put(setError(true));
    }
  } catch (error) {
    yield put(setError(true));
    console.log(error);
  } finally {
    yield put(hideLoading());
  }
}

function* changePassword(password, token) {
  try {
    yield put(showLoading());
    const client = getAuthClient(token);
    const { updateUserPassword = {} } = yield client.request(CHANGE_PASSWORD, { password });
    const { errors = [] } = { ...updateUserPassword };
    if (errors && errors.length > 0) {
      yield put(setError(true));
    } else {
      yield put(setError(false));
      yield put(setUpdated(true));
    }
  } catch (error) {
    yield put(setError(true));
    console.log(error);
  } finally {
    yield put(hideLoading());
  }
}

function* reset(email, role, callbackSucess, callbackError) {
  try {
    yield put(showLoading());
    const response = yield request(GRAPHQL_API, RESET_PASSWORD, { email, role });
    const { resetPassword: data = {} } = { ...response };
    const { success = false } = { ...data };
    if (success) {
      callbackSucess();
    } else {
      callbackError();
    }
  } catch (error) {
    yield put(setError(true));
    console.log(error);
  } finally {
    yield put(hideLoading());
  }
}

function* sendFeedback(data) {
  try {
    yield put(showLoading());
    // TODO: CHECK FIREBASE SDK
    // yield firestore().collection('comments').doc().set(data);
    yield put(setError(false));
    yield put(setSent(true));
  } catch (error) {
    yield put(setError(true));
    console.log(error);
  } finally {
    yield put(hideLoading());
  }
}

function* logout() {
  try {
    yield localStorage.removeItem(KEY_USER);
    window.location.href = '/';
    yield put(setUser(null));
  } catch (error) {
    yield put(setError(false));
    logEvent(Event.LOGOUT);
    console.log(error);
  }
}

function* setUserToken(token, patientType) {
  // TODO: CHECK THIS METHOD
  try {
    yield put(getPatientInfo({ token, patientType }));
    if (patientType !== 'Patient') {
      yield put(getRecommendedPrograms({ token }));
      yield put(getEmployeePrograms({ token }));
    }
    //  yield messaging().registerForRemoteNotifications();
    // const fcmToken = yield messaging().getToken();
    //  const client = getAuthClient(token);
    // yield client.request(FCM_TOKEN, { fcmToken });
  } catch (error) {
    yield put(setError(false));
    console.log(error);
  }
}

function* watchUser() {
  while (true) {
    const { payload = {} } = yield take(types.SET_USER);
    const { token, patientType } = { ...payload };
    if (token) {
      yield fork(setUserToken, token, patientType);
    }
  }
}

function* watchLogin() {
  while (true) {
    const { payload = {} } = yield take(types.LOGIN);
    const { email, password } = { ...payload };
    yield fork(login, email, password);
  }
}

function* watchLoginSura() {
  while (true) {
    const { payload = {} } = yield take(types.LOGIN_SURA);
    const { email, password } = { ...payload };
    yield fork(loginSura, email, password);
  }
}

function* watchReset() {
  while (true) {
    const { payload = {} } = yield take(types.RESET);
    const { email, callbackSucess, callbackError } = { ...payload };
    yield fork(reset, email, 'patient', callbackSucess, callbackError);
  }
}

function* watchLogout() {
  while (true) {
    yield take(types.LOGOUT);
    yield fork(logout);
  }
}

function* watchPassword() {
  while (true) {
    const { payload = {} } = yield take(types.CHANGE_PASSWORD);
    const { token = '', password = '' } = { ...payload };
    yield fork(changePassword, password, token);
  }
}

function* watchFeedback() {
  while (true) {
    const { payload = {} } = yield take(types.SEND_FEEDBACK);
    yield fork(sendFeedback, payload);
  }
}

export default function* root() {
  yield all([
    fork(watchLogin),
    fork(watchLoginSura),
    fork(watchReset),
    fork(watchLogout),
    fork(watchUser),
    fork(watchPassword),
    fork(watchFeedback),
  ]);
}
