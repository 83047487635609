import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosArrowBack } from 'react-icons/io';
import i18n from 'config/i18n';
import renderHTML from 'react-render-html';

import { updateCurrentProgramDay } from 'store/actions/programs';
import { queryParams } from 'utils/string';
import { handleScrollTo } from 'utils/handleScrollTo';
import { ROUTES } from 'utils/constants';

import Nav from 'components/Nav';

import './lectures.css';

const Lectures = () => {
  const {
    goBack,
    push,
    location: { search, state },
  } = useHistory();
  const { program_id: programId } = queryParams(search);

  const dispatch = useDispatch();
  const { employeePrograms = [], user = {} } = useSelector((state) => {
    const { programs = {}, auth = {} } = state;
    return { ...programs, ...auth };
  });
  const employeeProgram = employeePrograms.find((employeeProgram) => employeeProgram.id === programId);
  const original = employeeProgram?.program?.backgroundImage?.original;
  const thumb = employeeProgram?.program?.doctorImage?.thumb;
  const readingsDay = employeeProgram?.readingsDay || [];
  const readingsSteps = (readingsDay || []).map((reading) => reading.id);

  const [step, setStep] = useState(0);

  const lectureTitle = readingsDay[step]?.title;
  const lectureDescription = readingsDay[step]?.content || '';
  const lectureBackground = readingsDay[step]?.backgroundImage?.original;
  const isLastLecture = step === readingsDay.length - 1;

  useEffect(() => {
    handleScrollTo();
  }, []);

  const handleNextLecture = () => {
    if (isLastLecture) {
      handleScrollTo();
      dispatch(
        updateCurrentProgramDay({
          token: user.token,
          patientProgramId: programId,
          sessionStartAt: state.timestamp,
          programType: 'reading_day',
          navigate: push,
        }),
      );
      push(state.redirect ? ROUTES.HOME : `${ROUTES.PROGRAM}?id=${programId}`, { refetch: true });
    } else {
      handleScrollTo();
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handleGoBack = () => {
    if (step === 0) {
      goBack();
    } else {
      handleScrollTo();
      setStep((prevStep) => prevStep - 1);
    }
  };

  return (
    <>
      <Nav />
      <div className="lectures-container">
        <section className="lectures-background" style={{ backgroundImage: `url(${lectureBackground || original})` }} />
        <section className="lectures-content">
          <header className="lectures-header">
            <IoIosArrowBack className="program-header-back" onClick={handleGoBack} />
            <img className="program-card-image" src={thumb} alt="title" />
            <div className="program-header-right-side">
              <h3 className="program-header-title">{lectureTitle}</h3>
            </div>
            <ul className="lectures-steps">
              {readingsSteps.map((currentStep, index) => {
                const isCurrentStep = index <= step;
                return <li key={currentStep.id} className={`lectures-step ${isCurrentStep ? 'lectures-current-step' : ''}`} />;
              })}
            </ul>
          </header>
          <div>
            <div className="program-divider" />
            <section className="lectures-description">{renderHTML(lectureDescription)}</section>
          </div>
          <div className="program-divider" />
          <footer className="program-start-activities-footer">
            <button className="program-start-activities-btn" onClick={handleNextLecture}>
              {isLastLecture ? i18n.t('programs.lectures.endBtn') : i18n.t('programs.lectures.nextBtn')}
            </button>
          </footer>
        </section>
      </div>
    </>
  );
};

export default Lectures;
