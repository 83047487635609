import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import i18n from 'config/i18n';
import moment from 'moment';

import { capitalize } from 'utils/string';
import { useToggleModals } from 'utils/hooks';
import { useWindowSize } from 'utils/hooks';
import { ROUTES } from 'utils/constants';

import ReactModal from 'components/ReactModal';
import Modal from 'components/Modal';
import ModalCloseButton from 'components/ModalCloseButton';
import Label from 'components/Label';
import Icon from 'components/Icon';
import Button from 'components/Button';
import VideoPlayer from 'components/VideoPlayer';
import Nav from 'components/Nav';

const Plan = ({ plan, onCompleteDay }) => {
  const { plan: patientPlan = {}, currentDay = {}, id = '' } = { ...plan };
  const { time = 30 } = { ...patientPlan };
  const { exercises = [], weekTitle: week = '', weekNumber: day = 1 } = { ...currentDay };
  const weekTitle = i18n.t('dayDetail.weekTitle', { week: capitalize(week), day });

  const { push } = useHistory();

  const [exercise, setExercise] = useState({});
  const [modalExercise, setModalExercise] = useState(false);
  const { isModalOpen: isAlertOpen, setIsModalOpen: setIsAlertOpen } = useToggleModals('#modal-confirm');
  const { width } = useWindowSize();

  const memoEquipments = useMemo(() => {
    let equipments = (exercises || []).reduce((a, b) => {
      const { equipments = [] } = { ...b };
      return [...a, ...equipments];
    }, []);
    return (equipments = [...new Set(equipments)].sort());
  }, [exercises]);

  const toggleModalExercise = (exercise) => {
    if (!modalExercise) {
      setModalExercise(true);
      setExercise(exercise);
    } else {
      setModalExercise(false);
      setExercise({});
    }
  };

  const toggleAlert = () => setIsAlertOpen(!isAlertOpen);
  const completeDay = () =>
    onCompleteDay(undefined, () => {
      toggleAlert();
    });

  const timestamp = moment().unix();

  return (
    <>
      <Nav />
      <div className={'uk-container'} style={{ paddingTop: 80 }}>
        <div className={`${width > 720 ? 'uk-flex uk-flex-between uk-flex-top' : ''}`}>
          <div>
            <h2 className={'uk-text-bold uk-text-large uk-margin-top'}>{i18n.t('dayDetail.title')}</h2>
            <p className={'uk-text-success uk-text-bold'}>{weekTitle}</p>
          </div>
          <div className={`${width > 720 ? 'uk-margin-top' : 'uk-margin-bottom'} ${width > 436 && 'uk-button-group'}`}>
            <Button
              className={`${width < 436 && 'uk-margin-bottom'}`}
              primary
              onClick={() => {
                push(`${ROUTES.NEW_CIRCUIT}?plan_id=${id}`, { timestamp });
              }}>
              {i18n.t('dayDetail.start')}
            </Button>
          </div>
        </div>
        <Label>
          <Icon iconType={'clock'} iconRatio={'0.8'} />
          {i18n.t('dayDetail.time', { time })}
        </Label>
        <Label>
          <Icon iconType={'future'} iconRatio={'0.8'} />
          {i18n.t('dayDetail.exercises', { exercises: (exercises || []).length })}
        </Label>
        <hr />
        <p className={'uk-text-bold'}>{i18n.t('dayDetail.equipments')}</p>
        <div>
          {memoEquipments.map((item, index) => (
            <Label labelType={'success'} key={index}>
              {capitalize(item)}
            </Label>
          ))}
        </div>
        <hr />
        <ul className={'uk-list uk-list-divider'}>
          {exercises.map((exercise, index) => {
            const { id: exId = '', title: exTitle = '', image = '', reps = 0, sets = 0 } = { ...exercise };
            return (
              <li
                key={exId}
                onClick={() => toggleModalExercise(exercise)}
                className={'uk-flex uk-flex-middle'}
                style={{ cursor: 'pointer' }}>
                <div className={'uk-width-auto'}>
                  <img className={'uk-comment-avatar'} src={image} width={'80'} height={'80'} alt={exTitle} />
                </div>
                <div className={'uk-width-expand uk-margin-left'}>
                  <h4 className={'uk-margin-remove uk-text-bold uk-text-small'}>{capitalize(exTitle.trim())}</h4>
                  <p className={'uk-comment-meta uk-margin-remove uk-text-small'}>
                    {i18n.t('dayDetail.exData', { reps: reps || 10, sets })}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
        <ReactModal modalIsOpen={modalExercise} closeModal={toggleModalExercise}>
          <ModalCloseButton onClick={toggleModalExercise} />
          <div className={'uk-modal-header'}>
            <h2 className={'uk-text-bold uk-text-large'}>{i18n.t('exercise.title')}</h2>
          </div>
          <div className={'uk-modal-body'} data-uk-overflow-auto>
            <h4 className={'uk-text-primary uk-text-bold uk-text-center'}>{capitalize(exercise.title)}</h4>
            <div className={'uk-flex uk-flex-center uk-height-medium'}>
              <VideoPlayer source={exercise.video} data-uk-img style={{ width: '100%', ...(width < 448 && { height: '250px' }) }} />
            </div>
            <div className={'uk-flex uk-flex-around uk-padding-small'}>
              <div className={'uk-flex uk-flex-column uk-flex-middle uk-flex-around'}>
                <p className={'uk-margin-remove uk-text-bold uk-text-small uk-text-primary'}>{i18n.t('exercise.reps')}</p>
                <p className={'uk-margin-remove uk-text-small'}>{exercise.reps || '10'}</p>
              </div>
              <hr className={'uk-divider-vertical'} style={{ margin: 0, height: 60 }} />
              <div className={'uk-flex uk-flex-column uk-flex-middle uk-flex-around'}>
                <p className={'uk-margin-remove uk-text-bold uk-text-small uk-text-primary'}>{i18n.t('exercise.sets')}</p>
                <p className={'uk-margin-remove uk-text-small'}>{exercise.sets}</p>
              </div>
              <hr className={'uk-divider-vertical'} style={{ margin: 0, height: 60 }} />
              <div className={'uk-flex uk-flex-column uk-flex-middle uk-flex-around'}>
                <p className={'uk-margin-remove uk-text-bold uk-text-small uk-text-primary'}>{i18n.t('exercise.rest')}</p>
                <p className={'uk-margin-remove uk-text-small'}>{exercise.rest}</p>
              </div>
            </div>
            <p className={'uk-text-bold uk-text-small uk-margin-remove-top'}>{i18n.t('exercise.instructions')}</p>
            <ul className={'uk-list uk-list-hyphen'}>
              {`${exercise.instructions}`
                .split('- ')
                .filter((el) => !!el)
                .map((item, index) => (
                  <li className={'uk-text-muted uk-text-small'} key={index}>
                    {item}
                  </li>
                ))}
            </ul>
            {!!exercise.tips && (
              <div className={''}>
                <p className={'uk-text-bold uk-text-small uk-margin-remove-top'}>{i18n.t('exercise.tips')}</p>
                <ol className={'uk-list uk-list-hyphen'}>
                  {`${exercise.tips}`
                    .split('- ')
                    .filter((el) => !!el)
                    .map((item, index) => (
                      <li className={'uk-text-muted uk-text-small'} key={index}>
                        {item}
                      </li>
                    ))}
                </ol>
              </div>
            )}
          </div>
        </ReactModal>
        <Modal modalId={'modal-confirm'} closable={false}>
          <div className={'uk-modal-dialog uk-margin-auto-vertical'}>
            <ModalCloseButton onClick={toggleAlert} />
            <div className={'uk-modal-header'}>
              <h2 className={'uk-text-bold uk-text-large'}>{i18n.t('dayDetail.alert.title')}</h2>
            </div>
            <div className={'uk-modal-body'}>
              <p className={'uk-text-small'}>{i18n.t('dayDetail.alert.message')}</p>
            </div>
            <div className="uk-modal-footer uk-text-right">
              <Button onClick={toggleAlert}>{i18n.t('dayDetail.alert.cancel')}</Button>
              <Button primary onClick={completeDay}>
                {i18n.t('dayDetail.alert.ok')}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Plan;
