const en = {
  onboarding: {
    skip: 'Skip',
    next: 'Next',
    slide1: 'Your personalized plan\nalways with you',
    slide2: 'Perform your rehabilitation\nexercises anywhere',
    slide3: 'Always connected with your specialist\nfor feedback and suggestions',
  },
  login: {
    intro: 'Login to get access to your training plan granted by your therapist.',
    introSura: 'Login to get access to your training and food plan.',
    login: 'Sign In',
    loginPartners: 'Log In with Sura',
    forgot: 'Forgot password?',
    forgotIntro: "Confirm your email, we'll send you a link to reset your password",
    forgotButton: 'Recover password',
    forgotSuccess: 'Recovery mail was sent successfully',
    forgotError: 'Recovery mail could not be sent, please try again',
    disclaimer: 'By clicking on Sign In, you accept our',
    terms: 'Terms',
    policies: 'Privacy Policy.',
    and: '&',
    email: 'Email',
    dni: 'DNI',
    type: 'DNI type',
    password: 'Password',
    requiredEmail: 'Email is required',
    requiredPassword: 'Password is required',
    requiredType: 'DNI type is required',
    requiredDni: 'DNI is required',
    emailError: '* You must enter a valid email',
    passwordError: '* You must enter a password of at least 6 characters',
    urlPolicy: 'https://web.healthphy.co/privacidad',
    urlTerms: 'https://web.healthphy.co/terminos',
    //loginSuccess: 'Welcome',
    loginError: 'Invalid username and password, try again',
    invalidFields: 'Enter the required fields correctly',
    inactiveTitle: 'Account inactive',
    inactiveMessage: 'Your specialist has deactivated your account, contact him or write to info@healthphy.de to assign you one.',
    inactiveOk: 'Ok',
  },
  tabs: {
    today: 'Today',
    settings: 'Settings',
    tips: 'Tips',
    contact: 'Contact',
  },
  home: {
    today: 'Today',
    morning: 'Good morning,',
    afternoon: 'Good afternoon,',
    night: 'Good night,',
    exercises: 'Exercises',
    time: '{{time}} Minutes',
    attends: 'Attends you:',
    planEnd: 'Wait, your doctor will update your exercise plan',
    planMissing: 'Contact your doctor to create your exercise plan',
    call: 'Pending call',
    survey: 'Pending survey',
    callTitle: 'Welcome to HealthPhy',
    callMessage: 'Schedule a call with your specialist to start your therapy',
    event: 'Upcoming event',
    parts: 'Related body parts',
    recommended: 'Recommended Programs',
  },
  forceUpdate: {
    title: 'New version available',
    message: 'A new version is available in stores. You need to update the app to continue.',
    button: 'Update',
  },
  settings: {
    title: 'Profile',
    settings: 'Settings',
    myData: 'My data',
    myDataSub: 'Update your personal data.',
    clinic: 'Specialist',
    name: 'Nombre',
    description: 'Description',
    email: 'Email',
    dni: 'Document Id',
    birthday: 'Birth date',
    gender: 'Gender',
    policies: 'Privacy policy',
    terms: 'Terms & conditions',
    opinion: {
      title: 'Give us your opinion',
      message: 'We would love to know your opinion!',
      button: 'Send',
      successTitle: 'Thank you!',
      successMessage: 'Your comments were sent successfully',
      errorMessage: 'Your comments could not be sent, please try again.',
    },
    logout: 'SIGN OUT',
    changePassword: 'Change password',
    saveChanges: 'Save Changes',
    password: {
      showPassword: 'Show password',
      password: 'Password',
      confirm: 'Confirm password',
      wMatch: 'Password must match',
      wUpper: 'At least one uppercase letter (A-Z)',
      wLower: 'At least one lowercase letter (a-z)',
      wNumber: 'At least one number (0-9)',
      wSpecial: 'At least one special character',
      minLength: 'Be at least eight characters long',
    },
    modal: {
      success: {
        title: 'Successfully saved!',
        subtitle: 'Password has been updated successfully.',
        button: 'OK',
      },
      error: {
        title: 'Sorry!',
        subtitle: "Password couldn't be updated, please try again.",
        button: 'OK',
      },
    },
    version: 'Version {{version}}',
  },
  notAvailable: {
    title: 'Site under construction',
    subtitle: 'Perform your rehabilitation\nexercises anywhere',
  },
  dayDetail: {
    title: 'Exercises for Today',
    time: '{{time}} Minutes',
    exercises: '{{exercises}} Exercises',
    equipments: 'Required items:',
    exData: '{{sets}} sets · {{reps}} repetitions',
    weekTitle: 'Day {{day}} of {{week}}',
    button: 'Complete day',
    alert: {
      title: 'Mark as completed',
      message: 'Do you want to mark the exercise completed today?',
      ok: 'Agree',
      cancel: 'Cancel',
    },
    completedError: 'It was not possible to mark the day as completed, try again',
    completedTitle: '¡Congratulations!',
    completedMessage: 'You have completed your exercises session for the day',
    finishedMessage: 'You have successfully completed your exercise plan',
    completedButton: 'OK',
    start: 'Start Exercises',
    home: 'Go to home',
  },
  circuit: {
    alert: {
      title: 'Leave session',
      message: "You really want to leave today's session?",
      ok: 'Agree',
      cancel: 'Cancel',
    },
  },
  exercise: {
    title: 'Exercise Detail',
    play: 'Play',
    reps: 'Repetitions',
    sets: 'Sets',
    instructions: 'Instructions',
    rest: 'Rest',
    tips: 'Tips',
    requiredObjects: 'Required items',
  },
  calls: {
    title: 'Calls',
    greet:
      'Hello, my name is {{clinicName}} and I will be assisting you from now on in your physiotherapeutic process, schedule a call and I will answer questions about your exercise program.',
    connecting: 'Connecting...',
    connMessage: 'Please wait while your treating doctor connects to the video call',
    schedule: 'Schedule',
    requestTitle: 'Schedule a call',
    doctor: 'Choose an available date and time to schedule the call with your specialist',
    date: 'Date',
    time: 'Time',
    change: 'Change',
    scheduleCall: 'Schedule call',
    message: 'Message',
    errorTitle: 'Sorry!',
    errorSubtitle: "Call couldn't be scheduled, please try again.",
    errorUpdateTitle: "Request couldn't be updated, please try again.",
    errorButton: 'OK',
    tokenErrorTitle: 'Sorry!',
    tokenErrorSubtitle: 'The call is not yet available, check the scheduled time',
    tokenErrorButton: 'Ok',
    dateButton: 'Close',
    notAvailability: 'No availability on this day',
    notAvailabilityMsg: "Your specialist doesn't have availability for the selected day",
    bookingMessage: 'I would like to schedule a call for ',
    acceptMessage: 'Your call was ',
    acceptMessageMed: 'accepted',
    acceptMessageEnd: ' by your specialist for ',
    rejectedMessage: 'Your call was ',
    rejectedMessageMed: 'rejected',
    rejectedMessageEnd: ' by your specialist',
    cancel: 'Cancel',
    reschedule: 'Reschedule',
    enter: 'Join the call',
    of: 'of',
    at: 'at',
    cancelModal: {
      title: 'Cancel Call',
      message: 'Do you really want to cancel the call request?',
      ok: 'OK',
      cancel: 'Dismiss',
    },
  },
  notifications: {
    title: 'Notifications',
    description: 'Permissions setup',
    on: 'ON',
    off: 'OFF',
    empty: 'You currently have no notifications in your tray',
  },
  feedback: {
    title: 'How was your exercise day?',
    skip: 'Skip',
    description: 'We would like to know your opinion to improve in your next sessions.',
    likeTitle: "Did you like today's session?",
    difficulty0: 'Painless',
    difficulty1: 'Very Mild',
    difficulty2: 'Mild',
    difficulty3: 'Moderate',
    difficulty4: 'Severe',
    difficulty5: 'Unspeakable',
    difficultyTitle: 'What was the intensity of pain when performing the exercises?',
    commentsTitle: 'Any comment?',
    send: 'Send',
  },
  pain: {
    title: 'Queremos saber como te sientes hoy',
    subtitle: 'En una escala de 0 a 10 ¿Cual es la intensidad de tu dolor?',
    send: 'Send',
    level0: 'Painless',
    level2: 'Very Mild',
    level4: 'Mild',
    level6: 'Moderate',
    level8: 'Severe',
    level10: 'Unspeakable',
  },
  menu: {
    dashboard: 'Home',
    myplan: 'My plan',
    today: 'Exercises for today',
    contact: 'Contact',
    settings: 'Settings',
    notifications: 'Notifications',
  },
  programs: {
    sessionTitle: 'sessions',
    lectures: {
      nextBtn: 'Next lecture',
      endBtn: 'End day',
      alert: {
        message: 'Do you want to mark the lectures completed today?',
      },
    },
    enroll: {
      btn: 'Enroll in the program',
      modalTitle: 'Create your reminder',
      modalSubTitle: 'Create the habit to improve your health.',
      time: 'What time?',
      repeat: 'Repetition',
      chooseDays: 'Select on which days you would like to receive reminders.',
      start: 'Start',
    },
    enrolled: {
      sessions: '{{day}} sessions of {{duration}}',
      session: 'Sessions {{duration}}',
    },
    unenroll: {
      confirmTitle: 'Are you sure?',
      confirmMessage: 'finish programme  early and remove  all upcoming sessions',
      finishBtn: 'Finish',
      unactiveButton: 'Finish program early',
    },
    dayDisable: 'Unavailable day',
    dayDisableBody: 'The day you try to access is not yet enabled',
    endTitle: 'Congratulations!',
    endMessage: 'You have completed your program succesfully',
  },
  enrollmentProgram: {
    description: 'Description',
    activities: 'Activities',
    lectures: 'Lectures',
    lecture: 'Lecture',
    audio: 'Audios',
    exercises: 'Exercises',
    exercise: 'Exercise',
    completedLecturesMessage: 'You have completed your lectures session for the day',
    btn: {
      lectures: 'Start lectures',
      audio: 'Start audios',
      exercises: 'Start exercises',
    },
    headerCurrentDay: 'day {{day}} of {{duration}}',
    completed: 'Completed!',
    successEnroll: {
      title: 'You have registered to the program!',
      caption: 'You can now start your program focused on improving your health, well-being and life style.',
      btn: 'Continue',
    },
  },
};

export default en;
