const types = {
  GET_LOGS: '@contact/GET_LOGS',
  SET_LOGS: '@contact/SET_LOGS',
  SET_ERROR: '@contact/SET_ERROR',
  SET_TOKEN: '@contact/SET_TOKEN',
  SET_REQUEST_ERROR: '@contact/SET_REQUEST_ERROR',
  CALL_REQUEST: '@contact/CALL_REQUEST',
  GET_TOKEN: '@contact/GET_TOKEN',
  UPDATE_REQUEST: '@contact/UPDATE_REQUEST',
  GET_SLOTS: '@contact/GET_SLOTS',
  SET_SLOTS: '@contact/SET_SLOTS',
};

export default types;
