import React from 'react';
import Icon from 'components/Icon';

const CheckItem = ({ label, value }) => (
  <div className={'uk-flex uk-margin-small-bottom'}>
    <Icon iconType={'check'} iconRatio={'0.9'} color={value ? '#32d296' : '#999'}/>
    <p className={` uk-margin-remove uk-text-small  ${!value && 'uk-text-lighter uk-text-muted'}`}>{label}</p>
  </div>
);

export default CheckItem;