import types from '../types/contact';

export const setLogs = (payload) => ({ type: types.SET_LOGS, payload });
export const setError = (payload) => ({ type: types.SET_ERROR, payload });
export const setRequestError = (payload) => ({ type: types.SET_REQUEST_ERROR, payload });
export const requestCall = (payload) => ({ type: types.CALL_REQUEST, payload });
export const updateCall = (payload) => ({ type: types.UPDATE_REQUEST, payload });
export const getLogs = (payload) => ({ type: types.GET_LOGS, payload });
export const getToken = (payload) => ({ type: types.GET_TOKEN, payload });
export const setToken = (payload) => ({ type: types.SET_TOKEN, payload });
export const getSlots = (payload) => ({ type: types.GET_SLOTS, payload });
export const setSlots = (payload) => ({ type: types.SET_SLOTS, payload });
