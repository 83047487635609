import React, { useState, useEffect } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import i18n from 'config/i18n';
import { useToggleModals } from 'utils/hooks';
import { useWindowSize } from 'utils/hooks';
import Modal from 'components/Modal';
import ModalCloseButton from 'components/ModalCloseButton';
import Icon from 'components/Icon';
import Button from 'components/Button';
import VideoPlayer from 'components/VideoPlayer';

const Plan = ({ plan, onCompleteDay }) => {
  const { currentDay = {} } = { ...plan };
  const { exercises = [], id: currentDayId = '' } = { ...currentDay };
  const { isModalOpen: isAlertOpen, setIsModalOpen: setIsAlertOpen } = useToggleModals('#modal-confirm');
  const { isModalOpen: isInstructionsOpen, setIsModalOpen: setIsInstructionsOpen } = useToggleModals('#modal-instructions');
  const { width, height } = useWindowSize();

  const [exercise, setExercise] = useState();
  const [currentIndex, setCurrentIndex] = useState();
  const [timestamp] = useState(new Date().getTime());

  const toggleAlert = () => setIsAlertOpen && setIsAlertOpen(!isAlertOpen);
  const toggleInstructions = () => setIsInstructionsOpen && setIsInstructionsOpen(!isInstructionsOpen);

  const completeDay = () =>
    onCompleteDay(timestamp, () => {
      toggleAlert();
      window.location.href = `/feedback/${currentDayId}`;
    });

  useEffect(() => {
    if (currentIndex !== undefined) {
      setExercise(exercises[currentIndex]);
    } else if (!!exercise) {
      setExercise();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <div className={'uk-container'} style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100vh', maxHeight: '100vh', paddingTop: '80px' }}>
      {currentIndex !== undefined && (
        <>
          <div className={'uk-flex uk-flex-between'}>
            <p className={`${width > 444 && 'uk-text-large'} uk-text-emphasis uk-text-bold uk-margin-top uk-margin-remove-bottom`}>
              {i18n.t('dayDetail.title')}
            </p>
            <div className={'uk-margin-top'}>
              <Button
                disabled={!currentIndex}
                onClick={() => {
                  setCurrentIndex(currentIndex - 1);
                }}>
                <Icon iconType={'arrow-left'} iconRatio={'1.2'} color={'#454545'} />
              </Button>
              {currentIndex + 1 < exercises.length && (
                <Button
                  onClick={() => {
                    setCurrentIndex(currentIndex + 1);
                  }}>
                  <Icon iconType={'arrow-right'} iconRatio={'1.2'} color={'#454545'} />
                </Button>
              )}
              {currentIndex + 1 === exercises.length && (
                <Button onClick={toggleAlert} tooltip={i18n.t('dayDetail.button')}>
                  <Icon iconType={'check'} iconRatio={'1.2'} color={'#454545'} />
                </Button>
              )}
            </div>
          </div>
          <p className={`${width < 444 && 'uk-text-small'} uk-text-bold uk-text-primary`}>{exercise?.title}</p>
          <div className={'uk-flex uk-flex-wrap'}>
            <Button>
              <Icon iconType={'future'} iconRatio={'1'} color={'#454545'} />
              {i18n.t('dayDetail.exData', { ...exercise })}
            </Button>
            <Button>
              <Icon iconType={'lifesaver'} iconRatio={'1'} color={'#454545'} />
              {i18n.t('exercise.rest')}: {exercise?.rest}
            </Button>
            <Button onClick={toggleInstructions}>
              <Icon iconType={'info'} iconRatio={'1'} color={'#454545'} />
              {i18n.t('exercise.instructions')}
            </Button>
          </div>
          <br />
          {exercises.map((e, i) => {
            return (
              i === currentIndex && (
                <VideoPlayer
                  source={e?.video}
                  autoplay={true}
                  data-uk-img
                  style={{
                    width: '100%',
                    height: height > width ? '40%' : '98%',
                    marginBottom: '2%',
                  }}
                />
              )
            );
          })}
        </>
      )}

      {!!exercises && currentIndex === undefined && (
        <div className="uk-flex uk-flex-center uk-flex-middle uk-height-1-1">
          <CountdownCircleTimer
            isPlaying
            duration={5}
            size={300}
            strokeWidth={20}
            colors={[
              ['#8093FC', 0.5],
              ['#495fd7', 0.5],
            ]}
            onComplete={() => {
              setCurrentIndex(0);
            }}>
            {({ remainingTime }) => <h3 className={'uk-heading-medium uk-margin-remove-bottom'}>{remainingTime}</h3>}
          </CountdownCircleTimer>
        </div>
      )}
      <Modal modalId={'modal-confirm'} closable={false}>
        <div className={'uk-modal-dialog uk-margin-auto-vertical'}>
          <ModalCloseButton onClick={toggleAlert} />
          <div className={'uk-modal-header'}>
            <h2 className={'uk-text-bold uk-text-large'}>{i18n.t('dayDetail.alert.title')}</h2>
          </div>
          <div className={'uk-modal-body'}>
            <p className={'uk-text-small'}>{i18n.t('dayDetail.alert.message')}</p>
          </div>
          <div className="uk-modal-footer uk-text-right">
            <Button onClick={toggleAlert}>{i18n.t('dayDetail.alert.cancel')}</Button>
            <Button primary onClick={completeDay}>
              {i18n.t('dayDetail.alert.ok')}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal modalId={'modal-instructions'} closable={false}>
        <div className={'uk-modal-dialog uk-margin-auto-vertical'}>
          <ModalCloseButton onClick={toggleInstructions} />
          <div className={'uk-modal-header'}>
            <h2 className={'uk-text-bold uk-text-large'}>{i18n.t('exercise.instructions')}</h2>
          </div>
          <div className={'uk-modal-body'}>
            <p className={'uk-text-small'}>{exercise?.instructions}</p>
          </div>
          <div className="uk-modal-footer uk-text-right">
            <Button onClick={toggleInstructions}>{i18n.t('dayDetail.alert.ok')}</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Plan;
