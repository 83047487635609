export const LOGIN = `mutation signinUser($username: String!, $password: String!) {
    signinUser(authUser: {email: $username, password: $password}, role: "patient") {
      errors
      token
      clinic {
        id
        name
        email
        patientType
      }
    }
}`;

export const LOGIN_SURA = `mutation signinUser($username: String!, $password: String!, $partner: String) {
  signinUser(authUser: {email: $username, password: $password, partner: $partner}, role: "patient") {
    errors
    token
    clinic {
      id
      name
      email
    }
  }
}`;

export const CHANGE_PASSWORD = `mutation updateUserPassword($password: String!) {
  updateUserPassword(password: $password) {
    status
    errors
  }
}`;

export const FCM_TOKEN = `mutation updateFCMToken($fcmToken: String!) {
  updateFCMToken(fcmToken: $fcmToken) {
    errors
  }
}`;

export const RESET_PASSWORD = `mutation resetPassword($email: String!, $role: String!) {
  resetPassword(email: $email, role: $role) {
    success
    errors
  }
}`;
