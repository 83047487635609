import React from 'react';
import i18n from 'config/i18n';
import Input from 'components/Input';
import Icon from 'components/Icon';
import CheckItem from 'components/CheckItem';
import Button from 'components/Button';

const Password = ({ passwordForm, onChange, passwordValidations, showPassword, togglePassword, handleSubmit }) => {
  const { password = '', confirmPassword = '' } = { ...passwordForm };
  const { 
    minLength = '', 
    wMatch = '', 
    wUpper = '', 
    wLower = '', 
    wNumber = '', 
    wSpecial = '', 
    valid = '' 
  } = { ...passwordValidations };

  return (
    <div className={'uk-margin-small uk-margin-auto uk-flex uk-flex-column uk-width-large'}>
      <form onSubmit={handleSubmit}>
        <Input 
          label={i18n.t('settings.password.password')} 
          type={showPassword ? 'text' : 'password'} 
          name={'password'} 
          widthModifier={'setWidth'}
          value={password}
          onChange={onChange}
        />
        <Input 
          label={i18n.t('settings.password.confirm')} 
          type={showPassword ? 'text' : 'password'} 
          name={'confirmPassword'} 
          widthModifier={'setWidth'}
          value={confirmPassword}
          onChange={onChange} 
        />
        <div className={'uk-flex uk-margin-auto-right uk-margin-small-bottom'}>
          <p className={'uk-text-muted uk-text-small uk-margin-remove-bottom uk-margin-small-right'}>{i18n.t('settings.password.showPassword')}</p>
          <Icon iconType={showPassword ? 'unlock' : 'lock'} iconRatio={'0.9'} onClick={togglePassword} clickable/>
        </div>
        <CheckItem label={i18n.t('settings.password.wMatch')} value={wMatch} />
        <CheckItem label={i18n.t('settings.password.wUpper')} value={wUpper} />
        <CheckItem label={i18n.t('settings.password.wLower')} value={wLower} />
        <CheckItem label={i18n.t('settings.password.wNumber')} value={wNumber} />
        <CheckItem label={i18n.t('settings.password.wSpecial')} value={wSpecial} />
        <CheckItem label={i18n.t('settings.password.minLength')} value={minLength} />
        <div className={'uk-flex uk-flex-center uk-margin-small'}>
          <Button primary={valid} disabled={!valid}>{i18n.t('settings.saveChanges')}</Button> 
        </div>  
      </form>
    </div>
  );
}

export default Password;