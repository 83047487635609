import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

Modal.setAppElement('#root');

const ModalComponent = ({ isWidth, modalIsOpen, afterOpenModal, closeModal, children, modalProps = {} }) => {
  const customStyles = {
    content: {
      width: '95%',
      maxWidth: `${isWidth || '600px'}`,
      maxHeight: '80vh',
      padding: '0',
      border: 'none',
      borderRadius: '8px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      background: '#fff',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      overflowY: 'auto',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.6)',
    },
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Confirmación"
      {...modalProps}>
      {children}
    </Modal>
  );
};

ModalComponent.defaultProps = {
  afterOpenModal: () => {},
};

ModalComponent.propTypes = {
  children: PropTypes.node.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  afterOpenModal: PropTypes.func,
};

export default ModalComponent;
