import React, { useEffect } from 'react';
import { showNotification } from 'utils/notification';
import { useSelector, useDispatch } from 'react-redux';
import { requestCall, getLogs, updateCall, getToken, getSlots } from 'store/actions/contact';

import Contact from './Contact';

const ContainerContact = (props) => {

  const { 
    patientData: patientInfo = {}, 
    token = '', 
    logs, 
    requestError, 
    callToken: opentokToken, 
    ready, 
    loading, 
    timeSlots 
  } = useSelector((state) => {
      const { patient = {}, auth = {}, contact = {}, ui = {} } = { ...state };
      const { plan = {} } = { ...patient };
      const { user = {} } = { ...auth };
      const { patient: patientData = {} } = { ...plan };
      const { token: authToken = '' } = { ...user };
      const { token: callToken = '', slots = [] } = { ...contact };
      const { loading: uiLoading = false } = { ...ui };
      return { ...user, patientData, ...contact, token: authToken, callToken, loading: uiLoading, timeSlots: slots };
    },
  );

  const { id: bookingId = null, versions = [] } = { ...logs };
  const dispatch = useDispatch();

  const fetch = () => dispatch(getLogs({ token }));
  useEffect(() => {
    if (!!token) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (!!opentokToken && !!ready) {
      localStorage.setItem('opentok_token', opentokToken);
      window.open(`/call?sid=${sessionId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opentokToken, ready]);

  useEffect(() => {
    if (requestError) {
      showNotification(bookingId ? 'errorUpdateCall' : 'errorRequestCall', 'danger', 'topRight');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestError]);

  const onJoinCall = async () => {
    let body = { token };
    if (bookingId) {
      body = { ...body, bookingId };
      dispatch(getToken(body));
    }
    if (!ready) showNotification('unready', 'warning', 'topRight');
  };

  const onRequest = (payload, callback) => {
    let body = { token, ...payload };
    let action = requestCall;
    if (bookingId) {
      body = { ...body, status: 'awaiting_clinic', bookingId };
      action = updateCall;
    }
    dispatch(action(body));
    callback();
  };

  const handleCancelCall = (callback) => {
    dispatch(updateCall({ token, bookingId, status: 'canceled' }));
    callback();
  };

  const onFetchSlots = (day) => dispatch(getSlots({ token, day }));

  const messages = (versions || []).map((version, index) => {
    const { logs: versionLog = [] } = { ...version };
    const { status = [], message = [], updated_at = [], start_at = [], session_id } = { ...versionLog };
    const { start_at: start = [], message: msg = [], } = { ...versions[index - 1]?.logs };
    if (index % 2 === 0 && index > 0) {
      return {
        start_at: (start || [])[1],
        message: (msg || [])[1],
        status: (status || [])[1],
        updated_at: (updated_at || [])[1],
        session_id: (session_id || [])[1],
      };
    }
    return {
      status: (status || [])[1],
      message: (message || [])[1],
      updated_at: (updated_at || [])[1],
      start_at: (start_at || [])[1],
      session_id: (session_id || [])[1],
    };
  });
  const sidMessage = messages.find(({ session_id }) => !!session_id);
  const { session_id: sessionId = '' } = { ...sidMessage };

  return (
    <Contact
      patient={patientInfo}
      onRequest={onRequest}
      onCancelCall={handleCancelCall}
      logs={logs}
      onFetchSlots={onFetchSlots}
      loading={loading}
      timeSlots={timeSlots}
      onJoinCall={onJoinCall}
      messages={messages}
    />
  );
}

export default ContainerContact;