import React from 'react';
import i18n from 'config/i18n';
import { AiOutlineCheck } from 'react-icons/ai';

import { PROGRAM_VIEWS } from 'views/Program/Program';

import './program-activities.css';

const ProgramActivities = ({ employeeProgram, onProgramView }) => {
  const title = employeeProgram?.program?.planTemplate?.title;
  const image = employeeProgram?.program?.planTemplate.image;
  const isMultipleExercises = employeeProgram?.planDay?.dayExercises.length > 1;
  const exercisesLength = employeeProgram?.planDay?.dayExercises.length;
  const isExercises = employeeProgram?.planDay?.dayExercises.length > 0;

  const lecturesTitle = employeeProgram?.program?.readingTemplate?.title;
  const lecturesImage = employeeProgram?.program?.readingTemplate?.backgroundImage?.thumb;
  const isMultipleLectures = employeeProgram?.readingsDay.length > 1;
  const lecturesLength = employeeProgram?.readingsDay.length;
  const isLectures = employeeProgram?.readingsDay.length > 0;

  const readingDay = employeeProgram?.currentReadingDay;
  const planDay = employeeProgram?.currentPlanDay;
  const programDay = employeeProgram?.currentProgramDay;
  const isLecturesDone = employeeProgram && readingDay > programDay;
  const isExercisesDone = employeeProgram && planDay > programDay;

  return (
    <section className="program-activities-container">
      <h3 className="program-desc-title">{i18n.t('enrollmentProgram.activities')}</h3>
      {isLectures && (
        <button
          className={`program-activities-card ${isLecturesDone ? 'card-blocked' : ''}`}
          onClick={() => onProgramView(PROGRAM_VIEWS.LECTURES)}>
          <img src={lecturesImage || image} alt={lecturesTitle} className="program-activities-img" />
          <div className="program-activities-info">
            <span className="program-activities-label">{i18n.t('enrollmentProgram.lectures')}</span>
            <h4>{lecturesTitle}</h4>
            <span className="program-activities-qty">
              {isMultipleLectures
                ? `${lecturesLength} ${i18n.t('enrollmentProgram.lectures')}`
                : `${lecturesLength} ${i18n.t('enrollmentProgram.lecture')}`}
            </span>
          </div>
          {isLecturesDone && (
            <>
              <div className="program-activities-card-mask" />
              <div className="program-activities-card-mask mask-content">
                <div className="mask-content-layout">
                  <div className="mask-content-check-icon">
                    <AiOutlineCheck />
                  </div>
                  <span className="program-activities-card-mask-label">{i18n.t('enrollmentProgram.completed')}</span>
                </div>
              </div>
            </>
          )}
        </button>
      )}
      {isExercises && (
        <button
          className={`program-activities-card ${isExercisesDone ? 'card-blocked' : ''}`}
          onClick={() => onProgramView(PROGRAM_VIEWS.EXERCISES)}>
          <img src={image} alt={title} className="program-activities-img" />
          <div className="program-activities-info">
            <span className="program-activities-label">{i18n.t('enrollmentProgram.exercises')}</span>
            <h4>{title}</h4>
            <span className="program-activities-qty">
              {isMultipleExercises
                ? `${exercisesLength} ${i18n.t('enrollmentProgram.exercises')}`
                : `${exercisesLength} ${i18n.t('enrollmentProgram.exercise')}`}
            </span>
          </div>
          {isExercisesDone && (
            <>
              <div className="program-activities-card-mask" />
              <div className="program-activities-card-mask mask-content">
                <div className="mask-content-layout">
                  <div className="mask-content-check-icon">
                    <AiOutlineCheck />
                  </div>
                  <span className="program-activities-card-mask-label">{i18n.t('enrollmentProgram.completed')}</span>
                </div>
              </div>
            </>
          )}
        </button>
      )}
    </section>
  );
};

export default ProgramActivities;
