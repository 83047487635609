import { combineReducers } from 'redux';

import ui from './ui';
import auth from './auth';
import patient from './patient';
import contact from './contact';
import player from './player';
import programs from './programs';

export default combineReducers({ ui, auth, patient, contact, player, programs });
