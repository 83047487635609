import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import i18n from 'config/i18n';
import { ROUTES } from 'utils/constants';

import Icon from 'components/Icon';
import Button from 'components/Button';

import like1 from 'assets/images/like1.webp';
import like2 from 'assets/images/like2.webp';
import like3 from 'assets/images/like3.webp';
import like4 from 'assets/images/like4.webp';
import like5 from 'assets/images/like4.webp';

const Feedback = (props) => {
  const { push } = useHistory();
  const { onSend = () => {} } = { ...props };
  const [like, setLike] = useState(5);
  const [difficulty, setDifficulty] = useState(3);
  const [comment, setComment] = useState();
  const onContinue = () => {
    push(ROUTES.HOME);
  };
  return (
    <div className={'uk-flex uk-flex-center uk-flex-middle uk-flex-column uk-height-1-1'}>
      <div>
        <div>
          <h2 className={'uk-heading-small uk-text-center uk-margin-top uk-margin-remove-bottom'}>{i18n.t('feedback.title')}</h2>
          <p className={'uk-text-center'}>{i18n.t('feedback.description')}</p>
        </div>
      </div>
      <div className={'uk-margin-top uk-text-center'}>
        <div className={'uk-margin-top uk-text-center'}>
          <p>
            {i18n.t('feedback.difficultyTitle')}: <b>{i18n.t(`feedback.difficulty${difficulty}`)}</b>
          </p>
          <div>
            <input
              className="uk-range uk-primary"
              type="range"
              value={difficulty}
              min="0"
              max="5"
              step="1"
              onChange={(e) => setDifficulty(e.target.value)}></input>
          </div>
        </div>
        <div className={'uk-margin-top'}>
          <p>{i18n.t('feedback.likeTitle')}</p>
          <div>
            <Button secondary={like === 1} onClick={() => setLike(1)}>
              <img src={like1} alt={'1'} style={{ width: '60px', padding: '10px' }} />
            </Button>
            <Button secondary={like === 2} onClick={() => setLike(2)}>
              <img src={like2} alt={'2'} style={{ width: '60px', padding: '10px' }} />
            </Button>
            <Button secondary={like === 3} onClick={() => setLike(3)}>
              <img src={like3} alt={'3'} style={{ width: '60px', padding: '10px' }} />
            </Button>
            <Button secondary={like === 4} onClick={() => setLike(4)}>
              <img src={like4} alt={'4'} style={{ width: '60px', padding: '10px' }} />
            </Button>
            <Button secondary={like === 5} onClick={() => setLike(5)}>
              <img src={like5} alt={'5'} style={{ width: '60px', padding: '10px' }} />
            </Button>
          </div>
        </div>
        <div className="uk-margin-top">
          <textarea
            className="uk-textarea"
            rows="5"
            placeholder={i18n.t('feedback.commentsTitle')}
            value={comment}
            onChange={(e) => setComment(e.target.value)}></textarea>
        </div>
        <br />
        <div className={'uk-margin-top'}>
          <div className="uk-button-group">
            <Button onClick={onContinue}>
              <Icon iconType={'close'} iconRatio={'1.2'} color={'#454545'} />
              {i18n.t('feedback.skip')}
            </Button>
            <Button primary disabled={!difficulty || !like || !comment} onClick={() => onSend(difficulty, like, comment, onContinue)}>
              <Icon iconType={'check'} iconRatio={'1.2'} color={!difficulty || !like || !comment ? '#454545' : '#fff'} />
              {i18n.t('feedback.send')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
