import { take, put, fork, all } from 'redux-saga/effects';
import types from 'store/types/programs';
import { showLoading, hideLoading } from 'store/actions/ui';
import {
  GET_RECOMMENDED_PROGRAMS,
  GET_EMPLOYEE_PROGRAMS,
  ENROLL_TO_PROGRAM,
  UPDATE_CURRENT_PROGRAM_DAY,
  SET_REMINDER,
  UNACTIVE_PROGRAM,
} from 'store/queries/programs';
import { getAuthClient } from 'utils/graphql';
import { logoutRequest } from 'store/actions/auth';
import {
  setError,
  setRecommendedPrograms,
  setEmployeePrograms,
  setSuccessEnroll,
  setSuccessReminder as setSuccessReminderAction,
  getEmployeePrograms as getEmployeeProgramsAct,
} from 'store/actions/programs';
import { getPatientInfo } from 'store/actions/patient';
import { KEY_USER } from 'config/constants';

function* getRecommendedPrograms(token, navigate) {
  try {
    yield put(showLoading());
    const client = getAuthClient(token);
    const response = yield client.request(GET_RECOMMENDED_PROGRAMS);
    const { recommendedPrograms } = { ...response };
    yield put(setRecommendedPrograms(recommendedPrograms));
  } catch (error) {
    yield put(setError(true));
    if (`${error}`.indexOf('authenticate to perform this action') !== -1) {
      yield put(logoutRequest());
      yield localStorage.removeItem(KEY_USER);
      !!navigate && navigate('login');
    }
    console.log(error);
  } finally {
    yield put(hideLoading());
  }
}

function* enrollToProgram(token, programId, reminder, navigate) {
  try {
    yield put(showLoading());
    const client = getAuthClient(token);
    const response = yield client.request(ENROLL_TO_PROGRAM, { programId, reminder });
    yield put(setSuccessEnroll(response.success));
  } catch (error) {
    yield put(setError(true));
    if (`${error}`.indexOf('authenticate to perform this action') !== -1) {
      yield put(logoutRequest());
      yield localStorage.removeItem(KEY_USER);
      !!navigate && navigate('login');
    }
    console.log(error);
  } finally {
    yield put(hideLoading());
  }
}

function* setReminder(token, reminder, navigate, patientType) {
  try {
    yield put(showLoading());
    const client = getAuthClient(token);
    const response = yield client.request(SET_REMINDER, { reminder });
    if (response.setDefaultReminder.success) {
      yield put(setSuccessReminderAction(response.setDefaultReminder.success));
      yield put(getPatientInfo({ token, navigate, patientType }));
    }
  } catch (error) {
    yield put(setError(true));
    if (`${error}`.indexOf('authenticate to perform this action') !== -1) {
      yield put(logoutRequest());
      yield localStorage.removeItem(KEY_USER);
      !!navigate && navigate('login');
    }
    console.log(error);
  } finally {
    yield put(hideLoading());
  }
}

function* getEmployeePrograms(token, navigate) {
  try {
    yield put(showLoading());
    const client = getAuthClient(token);
    const response = yield client.request(GET_EMPLOYEE_PROGRAMS);
    const { employeePrograms } = { ...response };
    yield put(setEmployeePrograms(employeePrograms));
  } catch (error) {
    yield put(setError(true));
    if (`${error}`.indexOf('authenticate to perform this action') !== -1) {
      yield put(logoutRequest());
      yield localStorage.removeItem(KEY_USER);
      !!navigate && navigate('login');
    }
  } finally {
    yield put(hideLoading());
  }
}

function* updateCurrentProgramDay(token, patientProgramId, sessionStartAt, programType, navigate) {
  try {
    yield put(showLoading());
    const client = getAuthClient(token);
    const response = yield client.request(UPDATE_CURRENT_PROGRAM_DAY, {
      patientProgramId,
      sessionStartAt: sessionStartAt.toString(),
      programType,
    });
    if (response.updateCurrentProgramDay.success) {
      yield put(getEmployeeProgramsAct({ token, navigate }));
    }
    // yield put(setEmployeePrograms(employeePrograms));
  } catch (error) {
    yield put(setError(true));
    if (`${error}`.indexOf('authenticate to perform this action') !== -1) {
      yield put(logoutRequest());
      yield localStorage.removeItem(KEY_USER);
      !!navigate && navigate('login');
    }
    console.log(error);
  } finally {
    yield put(hideLoading());
  }
}

function* unactivePatientProgram(token, patientProgramId, navigate) {
  try {
    yield put(showLoading());
    const client = getAuthClient(token);
    const response = yield client.request(UNACTIVE_PROGRAM, {
      patientProgramId,
    });
    if (response.unactivePatientProgram.success) {
      yield put(getEmployeeProgramsAct({ token, navigate }));
    }
  } catch (error) {
    yield put(setError(true));
    if (`${error}`.indexOf('authenticate to perform this action') !== -1) {
      yield put(logoutRequest());
      yield localStorage.removeItem(KEY_USER);
      !!navigate && navigate('login');
    }
  } finally {
    yield put(hideLoading());
  }
}

function* watchRecommendedPrograms() {
  while (true) {
    const { payload = {} } = yield take(types.GET_RECOMMENDED_PROGRAMS);
    const { token = '', navigate } = { ...payload };
    yield fork(getRecommendedPrograms, token, navigate);
  }
}

function* watchEnrollToProgram() {
  while (true) {
    const { payload = {} } = yield take(types.ENROLL_TO_PROGRAM);
    const { token = '', navigate, programId = '', reminder = {} } = { ...payload };
    yield fork(enrollToProgram, token, programId, reminder, navigate);
  }
}

function* watchSetReminder() {
  while (true) {
    const { payload = {} } = yield take(types.SET_REMINDER);
    const { token = '', navigate, reminder = {}, patientType = '' } = { ...payload };
    yield fork(setReminder, token, reminder, navigate, patientType);
  }
}

function* watchEmployeePrograms() {
  while (true) {
    const { payload = {} } = yield take(types.GET_EMPLOYEE_PROGRAMS);
    const { token = '', navigate, path = null } = { ...payload };
    yield fork(getEmployeePrograms, token, navigate, path);
  }
}

function* watchUpdateCurrentProgramDay() {
  while (true) {
    const { payload = {} } = yield take(types.UPDATE_CURRENT_PROGRAM_DAY);
    const { token = '', navigate, patientProgramId = '', sessionStartAt = '', programType = '' } = { ...payload };
    yield fork(updateCurrentProgramDay, token, patientProgramId, sessionStartAt, programType, navigate);
  }
}

function* watchUnactivePatientProgram() {
  while (true) {
    const { payload = {} } = yield take(types.UNACTIVE_PROGRAM);
    const { token = '', navigate, patientProgramId = '' } = { ...payload };
    yield fork(unactivePatientProgram, token, patientProgramId, navigate);
  }
}

export default function* root() {
  yield all([
    fork(watchRecommendedPrograms),
    fork(watchEnrollToProgram),
    fork(watchEmployeePrograms),
    fork(watchUpdateCurrentProgramDay),
    fork(watchSetReminder),
    fork(watchUnactivePatientProgram),
  ]);
}
