import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword, setUpdated, setError } from 'store/actions/auth';
import { KEY_USER } from 'config/constants';
import { showNotification } from 'utils/notification';

import Password from './Password';

const ContainerPassword = (props) => {

  const { error = false, updated = false } = useSelector((state) => {
    const { auth = {} } = { ...state };
    return { ...auth };
  });
  const authData = JSON.parse(localStorage.getItem(KEY_USER) || '{}');
  const { token = '' } = { ...authData };
  const dispatch = useDispatch();

  const [passwordForm, setPasswordForm] = useState({ password: '', confirmPassword: ''});
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (updated || error) {
      dispatch(setUpdated(false));
      dispatch(setError(false));
      showNotification(updated ? 'success' : 'error', updated ? 'success' : 'danger', 'topRight');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated, error]);

  const { password = '', confirmPassword = '' } = { ...passwordForm };
  const minLength = password.length >= 8;
  const wMatch = !!password && password === confirmPassword;
  const wUpper = /(.*[A-Z])/g.test(password);
  const wLower = /(.*[a-z])/g.test(password);
  const wNumber = /(.*[0-9])/g.test(password);
  const wSpecial = /[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g.test(password);
  const valid = minLength && wMatch && wUpper && wLower && wSpecial && wNumber;
  const passwordValidations = { minLength, wMatch, wUpper, wLower, wNumber, wSpecial, valid };

  const handleInputChange = (e) => {
    setPasswordForm(prevInputData => ({
      ...prevInputData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(changePassword({ password, token }));
  };
  const togglePassword = () => setShowPassword(!showPassword);

  return (
    <Password 
      passwordForm={passwordForm} 
      onChange={handleInputChange}
      passwordValidations={passwordValidations}
      togglePassword={togglePassword}
      showPassword={showPassword}
      handleSubmit={handleSubmit}
    />
  );
}

export default ContainerPassword;