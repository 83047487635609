import { useEffect, useState, useCallback } from 'react';
import UIkit from 'uikit';

export const useToggleModals = (modalId) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      UIkit.modal(modalId).show();
    }
  }, [isModalOpen, modalId]);

  return { isModalOpen, setIsModalOpen };
};

export const useToggle = (initialValue = false) => {
  const [toggleValue, setToggleValue] = useState(initialValue);

  const toggle = useCallback(() => {
    setToggleValue((value) => !value);
  }, []);

  return [toggleValue, toggle];
};

export const useWindowSize = () => {
  const isSSR = typeof window === 'undefined';

  const [windowSize, setWindowSize] = useState({
    width: isSSR ? 1200 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight,
  });

  const handleResize = () => setWindowSize({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};

export const useFormData = (initialValues) => {
  const [formData, setFormData] = useState(initialValues);
  const [touched, setTouched] = useState({});

  const handleChange = ({ target: { name, value } }) => {
    setFormData((currentData) => ({
      ...currentData,
      [name]: value,
    }));
  };

  const handleBlur = ({ target: { name } }) => {
    setTouched((currentTouch) => ({
      ...currentTouch,
      [name]: true,
    }));
  };

  return {
    formData,
    touched,
    handleChange,
    handleBlur,
  };
};
