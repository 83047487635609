import { analytics } from 'config/firebase';

export const logEvent = (event, data) => analytics().logEvent(event, { ...data });

export const Event = {
  DAY_COMPLETED: 'DAY_COMPLETED',
  PLAN_COMPLETED: 'PLAN_COMPLETED',
  LOGIN: 'login',
  LOGIN_SURA: 'login_sura',
  LOGOUT: 'LOGOUT',
  CALL_REQUEST: 'CALL_REQUEST',
  CALL_TOKEN: 'CALL_TOKEN',
};
