const programTypes = {
  SET_RECOMMENDED_PROGRAMS: '@programs/SET_RECOMMENDED_PROGRAMS',
  GET_RECOMMENDED_PROGRAMS: '@programs/GET_RECOMMENDED_PROGRAMS',
  SET_EMPLOYEE_PROGRAMS: '@programs/SET_EMPLOYEE_PROGRAMS',
  ENROLL_TO_PROGRAM: '@programs/ENROLL_TO_PROGRAM',
  SET_SUCCESS_ENROLL: '@programs/SET_SUCCESS_ENROLL',
  GET_EMPLOYEE_PROGRAMS: '@programs/GET_EMPLOYEE_PROGRAMS',
  UPDATE_CURRENT_PROGRAM_DAY: '@programs/UPDATE_CURRENT_PROGRAM_DAY',
  SET_REMINDER: '@programs/SET_REMINDER',
  SET_SUCCESS_REMINDER: '@programs/SET_SUCCESS_REMINDER',
  UNACTIVE_PROGRAM: '@programs/UNACTIVE_PROGRAM',
};

export default programTypes;
