import React from 'react';
import { useHistory } from 'react-router-dom';
import i18n from 'config/i18n';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

import { ROUTES } from 'utils/constants';
import { useToggle } from 'utils/hooks';

import AlertModal from 'components/AlertModal';

import medal from 'assets/images/medal.png';

import './program-card.css';

const ProgramCard = ({ exercise }) => {
  const { push } = useHistory();
  const { program = {}, currentProgramDay = 1, id = '', updatedAt = '' } = exercise;
  const { doctorImage = {}, title = '', duration = 30 } = program;
  const { thumb = '' } = doctorImage;

  const [isDay, setIsDay] = useToggle();

  const currentDay = new Date().getDate();
  const dayUpdatedAt = new Date(updatedAt).getDate();
  const currentMonth = new Date().getMonth();
  const monthUpdatedAt = new Date(updatedAt).getMonth()

  const isDayBlocked = currentDay === dayUpdatedAt && currentMonth === monthUpdatedAt && currentProgramDay > 1;

  const handleStartExercises = () => {
    if (isDayBlocked) {
      setIsDay();
    } else {
      push(`${ROUTES.PROGRAM}?id=${id}`);
    }
  };

  return (
    <li>
      <button className={`program-card ${isDayBlocked ? 'program-card-blocked' : ''}`} onClick={handleStartExercises}>
        <img className="program-card-image" src={thumb} alt={title} />
        <div className="program-card-right-side">
          <h3 className="program-card-title">{title}</h3>
          <span className="program-card-time">
            {i18n.t('programs.enrolled.sessions', {
              day: currentProgramDay,
              duration: duration,
            })}
          </span>
        </div>
        <div className="program-card-more-info">
          <AiOutlineExclamationCircle />
        </div>
        <div className="program-card-medal">
          <img className="program-medal" src={medal} alt="medal" />
        </div>
      </button>
      {isDay && (
        <AlertModal
          modalIsOpen={isDay}
          closeModal={setIsDay}
          onConfirm={setIsDay}
          title={i18n.t('programs.dayDisable')}
          description={i18n.t('programs.dayDisableBody')}
        />
      )}
    </li>
  );
};

export default ProgramCard;
