import types from '../types/auth';

const init = {
  user: undefined,
  error: false,
  updated: false,
  logout: false,
  sent: false,
};

const reducer = (state = init, action) => {
  const { payload = null } = { ...action };
  switch (action.type) {
    case types.SET_USER:
      return {
        ...state,
        user: payload,
        logout: false,
      };
    case types.SET_SENT:
      return {
        ...state,
        sent: payload,
      };
    case types.SET_ERROR:
      return {
        ...state,
        error: payload,
        logout: false,
      };
    case types.SET_UPDATED:
      return {
        ...state,
        updated: payload,
      };
    case types.LOGOUT_REQUEST:
      return {
        ...state,
        logout: true,
      };
    default:
      return state;
  }
};

export default reducer;
