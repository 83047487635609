import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'ramda';
import i18n from 'config/i18n';

import { updateCurrentProgramDay } from 'store/actions/programs';
import { markAsCompleted } from 'store/actions/patient';
import { queryParams } from 'utils/string';
import { handleScrollTo } from 'utils/handleScrollTo';
import { ROUTES } from 'utils/constants';
import { useToggle } from 'utils/hooks';

import Nav from 'components/Nav';
import ExerciseVideo from 'components/ExerciseVideo';
import CurrentDayExercises from 'components/CurrentDayExercises';
import AlertModal from 'components/AlertModal';

import './new-circuit.css';

const NewCircuit = () => {
  const {
    push,
    location: { search, state },
  } = useHistory();
  const { program_id: programId, plan_id: planId } = queryParams(search);

  const dispatch = useDispatch();
  const {
    user = {},
    programs = {},
    plan = {},
  } = useSelector((state) => {
    const { auth = {}, patient = {}, programs = {} } = state;
    const { user = {} } = auth;
    const { plan = {} } = patient;
    return { user, plan, programs };
  });
  const { currentDay: currentPlanDay = {}, patient = {} } = plan;
  const { patientPlans = [] } = patient;
  const { employeePrograms = [] } = programs;
  const { patientType, token } = user;
  const isPatient = patientType === 'Patient';

  const [currentDay, setCurrentDay] = useState({});
  const [currentExercise, setCurrentExercise] = useState(0);
  const [alertConfirm, setIsAlertConfirm] = useToggle();

  useEffect(() => {
    if (isEmpty(currentDay)) {
      setCurrentDay((prevState) => {
        if (isPatient) {
          return { ...prevState, ...currentPlanDay };
        }
        if (planId) {
          const currentPlan = patientPlans.find((plan) => plan.id === planId);
          return { ...prevState, ...currentPlan?.currentDay };
        }
        if (programId) {
          const currentProgram = employeePrograms.find((program) => program.id === programId);
          return { ...prevState, ...currentProgram?.planDay };
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDay, isPatient, planId, programId]);

  const handleCompleteDay = () => {
    handleScrollTo();
    if (programId) {
      dispatch(
        updateCurrentProgramDay({
          token,
          patientProgramId: programId,
          sessionStartAt: `${state.timestamp}`,
          programType: 'plan_day',
          navigate: push,
        }),
      );
      push(state.redirect ? ROUTES.HOME : `${ROUTES.PROGRAM}?id=${programId}`, { refetch: true });
    } else {
      dispatch(markAsCompleted({ token, planId, startAt: `${state.timestamp}` }));
      push(ROUTES.HOME, { refetch: true });
    }
  };

  return (
    <>
      <Nav />
      <div className="new-circuit-layout">
        <div className="new-circuit-container">
          <ExerciseVideo
            currentExercise={currentExercise}
            currentDay={currentDay}
            setCurrentExercise={setCurrentExercise}
            setIsAlertConfirm={setIsAlertConfirm}
          />
          <CurrentDayExercises currentExercise={currentExercise} currentDay={currentDay} />
        </div>
      </div>
      {alertConfirm && (
        <AlertModal
          modalIsOpen={alertConfirm}
          closeModal={setIsAlertConfirm}
          onConfirm={handleCompleteDay}
          title={i18n.t('dayDetail.alert.title')}
          description={i18n.t('dayDetail.alert.message')}
        />
      )}
    </>
  );
};

export default NewCircuit;
