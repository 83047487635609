import { take, put, fork, all } from 'redux-saga/effects';
import types from 'store/types/contact';
import { getLogs as getLogsAct, setError, setLogs, setRequestError, setToken, setSlots } from 'store/actions/contact';
import { showLoading, hideLoading } from 'store/actions/ui';
import { GET_LOGS, CALL_REQUEST, UPDATE_REQUEST, GENERATE_TOKEN, GET_SLOTS } from 'store/queries/contact';
import { getAuthClient } from 'utils/graphql';
import { logEvent, Event } from 'config/events';

function* getLogs(token) {
  try {
    yield put(showLoading());
    yield put(setError(false));
    const client = getAuthClient(token);
    const { bookingLogs } = yield client.request(GET_LOGS);
    yield put(setLogs(bookingLogs));
  } catch (error) {
    yield put(setError(true));
    console.log(error);
  } finally {
    yield put(hideLoading());
  }
}

function* getSlots(day, token) {
  try {
    yield put(showLoading());
    yield put(setError(false));
    const client = getAuthClient(token);
    const { availableSlots: slots = [] } = yield client.request(GET_SLOTS, { day });
    yield put(setSlots((slots || []).filter((item) => item !== 'no availability')));
  } catch (error) {
    yield put(setError(true));
    console.log(error);
  } finally {
    yield put(hideLoading());
  }
}

function* requestCall(payload) {
  try {
    yield put(showLoading());
    yield put(setRequestError(false));
    const { token, startAt, message } = { ...payload };
    const client = getAuthClient(token);
    const { updateBookingRequest = {} } = yield client.request(CALL_REQUEST, { startAt, message });
    const { errors = [] } = { ...updateBookingRequest };
    if (errors && errors.length > 0) {
      yield put(setRequestError(true));
    } else {
      logEvent(Event.CALL_REQUEST);
      yield put(getLogsAct({ token }));
    }
  } catch (error) {
    yield put(setRequestError(true));
    console.log(error);
  } finally {
    yield put(hideLoading());
  }
}

function* updateCall(payload) {
  try {
    yield put(showLoading());
    yield put(setRequestError(false));
    const { token, startAt, message, status, bookingId } = { ...payload };
    const client = getAuthClient(token);
    const { createBookingRequest = {} } = yield client.request(UPDATE_REQUEST, { startAt, message, status, bookingId });
    const { errors = [] } = { ...createBookingRequest };
    if (errors && errors.length > 0) {
      yield put(setRequestError(true));
    } else {
      yield put(getLogsAct({ token }));
    }
  } catch (error) {
    yield put(setRequestError(true));
    console.log(error);
  } finally {
    yield put(hideLoading());
  }
}

function* getToken(payload) {
  try {
    yield put(showLoading());
    yield put(setRequestError(false));
    const { token, bookingId } = { ...payload };
    const client = getAuthClient(token);
    const { generateBookingToken = {} } = yield client.request(GENERATE_TOKEN, { bookingId });
    const { errors = [], token: openTok, ready } = { ...generateBookingToken };
    if (errors && errors.length > 0) {
      yield put(setRequestError(true));
    } else {
      yield put(setToken({ token: openTok, ready }));
      logEvent(Event.CALL_TOKEN, { bookingId });
    }
  } catch (error) {
    yield put(setRequestError(true));
    console.log(error);
  } finally {
    yield put(hideLoading());
  }
}

function* watchLogs() {
  while (true) {
    const { payload = {} } = yield take(types.GET_LOGS);
    const { token = '' } = { ...payload };
    yield fork(getLogs, token);
  }
}

function* watchSlots() {
  while (true) {
    const { payload = {} } = yield take(types.GET_SLOTS);
    const { token = '', day = '' } = { ...payload };
    yield fork(getSlots, day, token);
  }
}

function* watchRequest() {
  while (true) {
    const { payload = {} } = yield take(types.CALL_REQUEST);
    yield fork(requestCall, payload);
  }
}

function* watchUpdate() {
  while (true) {
    const { payload = {} } = yield take(types.UPDATE_REQUEST);
    yield fork(updateCall, payload);
  }
}

function* watchToken() {
  while (true) {
    const { payload = {} } = yield take(types.GET_TOKEN);
    yield fork(getToken, payload);
  }
}

export default function* root() {
  yield all([fork(watchLogs), fork(watchRequest), fork(watchUpdate), fork(watchToken), fork(watchSlots)]);
}
