import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';
import Input from 'components/Input';
import i18n from 'config/i18n';
import { ROUTES } from 'utils/constants';
import Logo from 'assets/images/logo-text.png';

const Login = (props) => {
  const {
    onLogin = () => {},
    handleChange = () => {},
    handleBlur = () => {},
    touched = {},
    loginCredentials = {},
    errors = {},
    token = '',
  } = { ...props };
  const { email = '', password = '' } = { ...loginCredentials };

  const history = useHistory();

  useEffect(() => {
    if (token) {
      history.push(ROUTES.HOME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    onLogin({ email, password });
  };

  if (token) return null;

  return (
    <div style={{ maxWidth: 300 }} className={'uk-position-center'}>
      <div className={'uk-flex uk-flex-center'}>
        <img src={Logo} style={{ width: 120, height: 135 }} alt={'logo HealthPhy'} />
      </div>
      <p className={'uk-text-center uk-text-small'}>{i18n.t('login.intro')}</p>
      <form onSubmit={handleSubmit}>
        <Input
          label={i18n.t('login.email')}
          type={'text'}
          name={'email'}
          widthModifier={'setWidth'}
          value={email}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors}
          touched={touched}
        />
        <Input
          label={i18n.t('login.password')}
          type={'password'}
          name={'password'}
          widthModifier={'setWidth'}
          value={password}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors}
          touched={touched}
        />
        <div className={'uk-margin-medium-top'}>
          <Button type={'submit'} className={'uk-margin-small-bottom'} primary fullwidth>
            {i18n.t('login.login')}
          </Button>
          <Button onClick={() => history.push(ROUTES.LOGIN_SURA)} fullwidth>
            {i18n.t('login.loginPartners')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Login;
