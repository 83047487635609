import types from '../types/ui';

export const showLoading = () => ({
  type: types.SET_LOADING,
  payload: true,
});

export const hideLoading = () => ({
  type: types.SET_LOADING,
  payload: false,
});
