import React from 'react';
import i18n from 'config/i18n';

import Modal from 'components/ReactModal';

import confirmImgage from 'assets/images/confirm-img.png';
import './alert-modal.css';

const AlertModal = ({ modalIsOpen, closeModal, title, description, onConfirm }) => {
  return (
    <Modal modalIsOpen={modalIsOpen} closeModal={closeModal} isWidth="400px">
      <section className="alert-container">
        <img src={confirmImgage} alt="confirm" className="alert-img" />
        <h2 className="enroll-success-title">{title}</h2>
        <p className="enroll-success-subtitle alert-subtitle">{description}</p>
        <footer className="alert-footer">
          <button className="cancel-button" onClick={closeModal}>
            {i18n.t('dayDetail.alert.cancel')}
          </button>
          <button className="confirm-button" onClick={onConfirm}>
            {i18n.t('dayDetail.alert.ok')}
          </button>
        </footer>
      </section>
    </Modal>
  );
};

export default AlertModal;
