import React, { useState, useRef } from 'react';
import i18n from 'config/i18n';
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';
import { BiPhoneOutgoing, BiMicrophone, BiMicrophoneOff, BiVideoOff, BiVideo, BiPhone } from 'react-icons/bi';

import { OPENTOK_API_KEY } from 'config/constants';
import { queryParams } from 'utils/string';

import Nav from 'components/Nav';

const Call = (props) => {
  const { location = {} } = { ...props };
  const { search = '' } = { ...location };
  const params = { ...queryParams(search) };
  const { sid: sessionId = '' } = { ...params };
  const token = localStorage.getItem('opentok_token');
  const iconProps = { size: 20, color: '#fff' };

  const [mic, setMic] = useState(true);
  const [video, setVideo] = useState(true);
  const [controls, setControls] = useState(false);
  const sessionRef = useRef();
  const publisherRef = useRef();

  const onCloseWindow = () => window.close();

  const onDisconnect = () => {
    if (!!sessionRef && !!sessionRef.current && !!sessionRef.current.sessionHelper) {
      sessionRef.current.sessionHelper.disconnect();
    }
    onCloseWindow();
  };

  const toggleMic = () => {
    if (!!publisherRef) {
      const { state = {} } = { ...publisherRef.current };
      const { publisher = {} } = { ...state };
      if (publisher) {
        publisher.publishAudio(!mic);
        setMic(!mic);
      }
    }
  };

  const toggleVideo = () => {
    if (!!publisherRef) {
      const { state = {} } = { ...publisherRef.current };
      const { publisher = {} } = { ...state };
      if (publisher) {
        publisher.publishVideo(!video);
        setVideo(!video);
      }
    }
  };

  const sessionEventHandlers = {
    streamCreated: () => setControls(true),
    //streamDestroyed: onCloseWindow,
    //connectionDestroyed: onCloseWindow,
  };

  return (
    <>
      <Nav />
      <div className={'uk-position-cover'}>
        <div className={'incoming-message uk-margin-small-left uk-position-center'}>
          <p className={'uk-margin-remove uk-text-center'}>{i18n.t('calls.connecting')}</p>
          <p className={'uk-margin-remove uk-text-center'}>{i18n.t('calls.connMessage')}</p>
        </div>
        <div className={'uk-position-center incoming-transition'}>
          <div className={'scaling awaiting-clinic-icon'}>
            <BiPhoneOutgoing />
          </div>
          <div className={'scaling circle1'}></div>
          <div className={'scaling circle2'}></div>
        </div>
        {!!token && (
          <OTSession eventHandlers={sessionEventHandlers} ref={sessionRef} apiKey={OPENTOK_API_KEY} sessionId={sessionId} token={token}>
            <OTPublisher ref={publisherRef} properties={{ showControls: false }} />
            <OTStreams>
              <OTSubscriber
                properties={{ height: window.innerHeight, width: window.innerWidth, showControls: false }}
                style={{ position: 'absolute' }}
              />
            </OTStreams>
          </OTSession>
        )}
        {controls && (
          <div className={'videocall-buttons'}>
            <div className={'control-button'} onClick={toggleVideo}>
              {video ? <BiVideo {...iconProps} /> : <BiVideoOff {...iconProps} />}
            </div>
            <div className={'hang-button'} onClick={onDisconnect}>
              <BiPhone size={32} />
            </div>
            <div className={'control-button'} onClick={toggleMic}>
              {mic ? <BiMicrophone {...iconProps} /> : <BiMicrophoneOff {...iconProps} />}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Call;
