import types from '../types/patient';

const init = {
  error: undefined,
  plan: undefined,
  completed: false,
  finished: false,
};

const reducer = (state = init, action) => {
  const { payload = null } = { ...action };
  switch (action.type) {
    case types.SET_ERROR:
      return {
        ...state,
        error: payload,
        plan: undefined,
      };
    case types.SET_PATIENT_PLAN:
      const { patient } = { ...state.plan };
      return {
        ...state,
        plan: { ...payload, patient },
        error: undefined,
      };
    case types.SET_PATIENT_INFO:
      return {
        ...state,
        plan: {
          ...state.plan,
          patient: payload,
        },
        error: undefined,
      };
    case types.SET_COMPLETED:
      return {
        ...state,
        completed: payload,
      };
    case types.SET_FINISHED:
      return {
        ...state,
        finished: payload,
      };
    default:
      return state;
  }
};

export default reducer;
