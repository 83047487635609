import React from 'react';
import { useDispatch } from 'react-redux';
import { sendFeedback } from 'store/actions/patient';
import { KEY_USER } from 'config/constants';

import Feedback from './Feedback';

const ContainerFeedback = (props) => {
  const authData = JSON.parse(localStorage.getItem(KEY_USER) || '{}');
  const { token = '' } = { ...authData };

  const { match = {} } = { ...props };
  const { params = {} } = { ...match };
  const { id: feedbackableId = '' } = { ...params };
  const dispatch = useDispatch();

  const onSend = (difficulty, like, comments, callback) => {
    dispatch(sendFeedback({ difficulty, like, comments, feedbackableId, token, callback }));
  };

  return <Feedback {...props} onSend={onSend} />;
};

export default ContainerFeedback;
