import React from 'react';

const widthModifiers = {
  large: 'uk-form-width-large',
  medium: 'uk-form-width-medium',
  small: 'uk-form-width-small',
  xSmall: 'uk-form-width-xsmall',
  setWidth: 'uk-width-1-1',
};

const TextArea = ({ label, name, widthModifier, rows, ...rest }) => {
  return (
    <div className={'uk-flex uk-flex-column uk-margin-small'}>
      <label htmlFor={name} className={'uk-text-muted uk-text-small uk-margin-small-bottom'}>
        {label}
      </label>
      <textarea
        style={{ resize: 'none' }}
        className={`uk-textarea ${widthModifiers[widthModifier]}`}
        id={name}
        name={name}
        rows={rows}
        {...rest}
      />
    </div>
  );
}

export default TextArea;