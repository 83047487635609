import { spawn } from 'redux-saga/effects';
import authSaga from './auth';
import patientSaga from './patient';
import contactSaga from './contact';
import programsSaga from './programs';

export default function* rootSaga() {
  yield spawn(authSaga);
  yield spawn(patientSaga);
  yield spawn(contactSaga);
  yield spawn(programsSaga);
}
