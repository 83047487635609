import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getPatientInfo, markAsRead } from 'store/actions/patient';
import Notifications from './Notifications';

const ContainerNotifications = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { notifications = [], user = {} } = useSelector((state) => {
    const { auth = {}, patient = {} } = { ...state };
    const { plan = {} } = { ...patient };
    const { patient: patientPlan = {} } = { ...plan };
    const { notifications: list = [] } = { ...patientPlan };
    return { ...auth, notifications: list };
  });

  const { token, patientType } = { ...user };

  const sortedNotifications = useMemo(() => {
    let sorted = notifications.sort((a, b) => {
      const { createdAt: first = '' } = { ...a };
      const { createdAt: second = '' } = { ...b };
      return new Date(second).getTime() - new Date(first).getTime();
    });
    return sorted;
  }, [notifications]);

  useEffect(() => {
    handleRefetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMarkAsRead = async (notificationId) => {
    dispatch(markAsRead({ notificationId, callback: handleRefetchNotifications, token }));
    history.push('contact');
  };

  const handleRefetchNotifications = async () => {
    dispatch(getPatientInfo({ token, navigate: history.push, patientType }));
  };

  return <Notifications notifications={sortedNotifications} onMarkAsRead={onMarkAsRead} />;
};

export default ContainerNotifications;
