import React from 'react';
import { useHistory } from 'react-router-dom';
import i18n from 'config/i18n';
import { IoMdTime } from 'react-icons/io';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

import { ROUTES } from 'utils/constants';
import { useToggle } from 'utils/hooks';

import AlertModal from 'components/AlertModal';

import './plan-card.css';

const PlanCard = ({ exercise = {} }) => {
  const { plan: patientPlan = {}, id = '', currentDayUpdatedAt = null } = exercise;
  const { title = '', time = 30, bodyParts = [] } = patientPlan;
  const { push } = useHistory();

  const [isDay, setIsDay] = useToggle();

  const currentDay = new Date().getDate();
  const dayUpdatedAt = new Date(currentDayUpdatedAt).getDate();
  const currentMonth = new Date().getMonth();
  const monthUpdatedAt = new Date(currentDayUpdatedAt).getMonth()

  const isDayBlocked = currentDayUpdatedAt !== null && currentDay === dayUpdatedAt && currentMonth === monthUpdatedAt;

  const handleStartExercises = () => {
    if (isDayBlocked) {
      setIsDay();
    } else {
      push(`${ROUTES.PLAN}?id=${id}`);
    }
  };

  return (
    <li>
      <button className={`plan-card ${isDayBlocked ? 'plan-card-blocked' : ''}`} onClick={handleStartExercises}>
        <img className="plan-card-image" src={bodyParts[0]?.image} alt={bodyParts[0]?.title} />
        <div className="plan-card-right-side">
          <h3 className="plan-card-title">{title}</h3>
          <span className="plan-card-time">
            {' '}
            <IoMdTime className="time-icon" /> {i18n.t('home.time', { time })}
          </span>
        </div>
        <div className="plan-card-more-info">
          <AiOutlineExclamationCircle />
        </div>
      </button>
      {isDay && (
        <AlertModal
          modalIsOpen={isDay}
          closeModal={setIsDay}
          onConfirm={setIsDay}
          title={i18n.t('programs.dayDisable')}
          description={i18n.t('programs.dayDisableBody')}
        />
      )}
    </li>
  );
};

export default PlanCard;
