import React from 'react';
import i18n from 'config/i18n';
import moment from 'moment';

import Nav from 'components/Nav';
import Icon from 'components/Icon';

import logoBlue from 'assets/images/logo-blue.svg';

const Notifications = ({ notifications, onMarkAsRead }) => {
  if ((notifications || []).length === 0) {
    return (
      <>
        <Nav />
        <div className={'uk-flex uk-flex-center uk-flex-middle uk-flex-column uk-height-1-1'} style={{ paddingTop: 150 }}>
          <Icon iconType={'bell'} iconRatio={'4'} />
          <br />
          <h2 className={'uk-margin-remove'}>{i18n.t('notifications.title')}</h2>
          <p className={'uk-text-normal uk-text-muted uk-text-center uk-padding-small'}>{i18n.t('notifications.empty')}</p>
        </div>
      </>
    );
  }
  return (
    <>
      <Nav />
      <div className={'uk-container'} style={{ paddingTop: 100 }}>
        {(notifications || []).map((notification, index) => {
          const { createdAt = new Date(), params = {}, id = '' } = { ...notification };
          const { title = '', message = '' } = { ...params };
          return (
            <div
              key={`${id}-${index}`}
              onClick={() => onMarkAsRead(id)}
              className={'uk-card uk-card-default uk-width-1-2@m uk-margin-small-bottom uk-flex uk-flex-middle'}
              style={{ paddingLeft: 15, cursor: 'pointer' }}>
              <img src={logoBlue} alt={'logo healthphy'} style={{ width: 50, height: 55 }} />
              <div className={'uk-padding-small'}>
                <p className={'uk-margin-remove uk-text-emphasis uk-text-bold'}>{title}</p>
                <p className={'uk-margin-remove uk-text-small'}>{message}</p>
                <p className={'uk-margin-small-bottom uk-text-small uk-text-muted'}>{moment(createdAt).format('DD/MM/YYYY - hh:mm A')}</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Notifications;
