const planSelector = `id
currentDay {
  id
  planNumber
  weekNumber
  weekTitle
  exercises {
    id
    image
    bodyParts
    equipments
    instructions
    levels
    reps
    rest
    sets
    tips
    title
    video
    videoUrl
    duration
  }
}
plan {
  title
  description
  id
  planType
  bodyParts {
    title
    image
  }
}
completed
currentDayUpdatedAt`;

export const GET_PATIENT_PLAN = `query getPatientPlan {
  getPatientPlan {
    ${planSelector}
  }
}`;

export const GET_PATIENT_INFO = `query getPatientInfo {
  getPatientInfo {
    id
    name
    gender
    email
    birthday
    cardId
    status
    defaultReminder{
      time
      days
      status
      reminderType
    }
    patientPlans {
      status
    }
    surveys {
      id
      form {
        title
        content
      }
      url
      typeSurvey
    }
    currentBooking {
      id
      startAt
      status
      message
      sessionId
    }
    currentEvent {
      id
      startAt
      title
      conferenceLink
    }
    clinic {
      id,
      name,
      email,
      profile {
        hasCalls
        avatar {
          original
          thumb
        }
        colors
        description
      }
    }
    notifications {
      createdAt
      id
      params
      readAt
      recipientId
      recipientType
      type
    }
  }
}`;

export const GET_EMPLOYEE_INFO = `query getEmployeeInfo {
  getEmployeeInfo {
    id
    name
    gender
    email
    birthday
    cardId
    status
    score
    defaultReminder {
      time
      days
      status
      reminderType
    }
    patientPlans {
      id
      status
      ${planSelector}
    }
    clinic {
      id,
      name,
      email,
      profile {
        hasCalls
        avatar {
          original
          thumb
        }
        colors
      }
    }
    company {
      id,
      name,
      email,
      profile {
        hasCalls
        hasRewards
        avatar {
          original
          thumb
        }
        colors
        description
      }
      products {
        avatar {
          original
          thumb
        }
        description
        id
        name
        points
        quantity
        status
      }
    }
    notifications {
      createdAt
      id
      params
      readAt
      recipientId
      recipientType
      type
    }
    currentBooking {
      id
      startAt
      status
      message
      sessionId
    }
    currentEvent {
      id
      startAt
      title
      conferenceLink
    }
    surveys {
      id
      form {
        title
        content
      }
      url
      typeSurvey
    }
  }
}`;

export const MARK_AS_COMPLETED = `mutation updateCurrentDay($planId: String!, $startAt: String) {
  updateCurrentDay(patientPlanId: $planId, sessionStartAt: $startAt) {
    errors
    patientPlan {
      ${planSelector}
    }
  }
}`;

export const SEND_FEEDBACK = `mutation createFeedback($feedbackInput: FeedbackInput!) {
  createFeedback(feedbackInput: $feedbackInput) {
    errors
    feedback {
      id
    }
  }
}`;

export const SEND_PAIN_LEVEL = `mutation createPatientInput($painLevel: Int!) {
  createPatientInput(painLevel: $painLevel) {
    errors
    patient {
      id
    }
  }
}`;

export const MARK_AS_READ = `mutation markAsRead($notificationId: String!) {
  markAsRead(notificationId: $notificationId) {
    errors
    notification {
      id
    }
  }
}`;
