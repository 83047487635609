import React from 'react';
import i18n from 'config/i18n';
import Password from 'components/Password';
import Modal from 'components/Modal';
import ModalCloseButton from 'components/ModalCloseButton';
import Initial from 'components/Initial';
import Icon from 'components/Icon';
import Nav from 'components/Nav';

const Profile = ({ plan }) => {
  const { patient = {} } = { ...plan };
  const { name = '', email = '', cardId = '', gender = '', birthday = '', clinic = {} } = { ...patient };
  const { name: clinicName = '', email: clinicEmail = '', profile = {} } = { ...clinic };
  const { description = '', avatar = {} } = { ...profile };
  const { thumb = '' } = { ...avatar };

  const initial = `${name}`.trim().charAt(0);

  return (
    <>
      <Nav />
      <div className={'uk-container'} style={{ paddingTop: 80 }}>
        <h2 className={'uk-text-bold uk-text-large uk-margin-top'}>{i18n.t('settings.title')}</h2>
        <Initial name={initial} />
        <div className={'uk-flex uk-flex-center uk-margin-top'}>
          <p className={'uk-text-bold'}>{name}</p>
        </div>
        <p className={'uk-text-bold uk-text-emphasis'}>{i18n.t('settings.settings')}</p>
        <div data-uk-toggle={'target: #modal-group-1'} className={'uk-flex uk-flex-middle uk-flex-between'} style={{ cursor: 'pointer' }}>
          <div>
            <p className={'uk-text-small uk-text-secondary uk-margin-remove'}>{i18n.t('settings.myData')}</p>
            <p className={'uk-text-muted uk-text-small uk-margin-remove'}>{i18n.t('settings.myDataSub')}</p>
          </div>
          <div>
            <Icon iconType={'chevron-right'} iconRatio={'1.4'} color={'#1e87f0'} />
          </div>
        </div>
        <hr />
        <div data-uk-toggle={'target: #modal-example'} className={'uk-flex uk-flex-middle uk-flex-between'} style={{ cursor: 'pointer' }}>
          <div>
            <p className={'uk-text-small uk-text-secondary uk-margin-remove'}>{i18n.t('settings.clinic')}</p>
            <p className={'uk-text-muted uk-text-small uk-margin-remove'}>{clinicName}</p>
          </div>
          <div>
            <Icon iconType={'chevron-right'} iconRatio={'1.4'} color={'#1e87f0'} />
          </div>
        </div>
        <hr />
        <a className={'uk-link-text'} href={i18n.t('login.urlTerms')} target={'_blank'} rel={'noopener noreferrer'}>
          <p className={'uk-text-bold'}>{i18n.t('settings.terms')}</p>
        </a>
        <hr />
        <a className={'uk-link-text'} href={i18n.t('login.urlPolicy')} target={'_blank'} rel={'noopener noreferrer'}>
          <p className={'uk-text-bold'}>{i18n.t('settings.policies')}</p>
        </a>
        <hr />
        <p className={'uk-text-bold uk-margin-remove-top'}>{i18n.t('settings.opinion.title')}</p>
        <hr />
        <Modal modalId={'modal-group-1'} closable={true}>
          <div className={'uk-modal-dialog'}>
            <ModalCloseButton />
            <div className={'uk-modal-header'}>
              <h2 className={'uk-text-bold uk-text-large'}>{i18n.t('settings.title')}</h2>
            </div>
            <div className={'uk-modal-body'} data-uk-overflow-auto>
              <Initial name={initial} />
              <div className={'uk-margin'}>
                <p className={'uk-text-small uk-text-secondary uk-margin-remove'}>{i18n.t('settings.name')}</p>
                <p className={'uk-text-muted uk-text-small uk-margin-remove'}>{name}</p>
              </div>
              <div className={'uk-margin'}>
                <p className={'uk-text-small uk-text-secondary uk-margin-remove'}>{i18n.t('settings.email')}</p>
                <p className={'uk-text-muted uk-text-small uk-margin-remove'}>{email}</p>
              </div>
              <div className={'uk-margin'}>
                <p className={'uk-text-small uk-text-secondary uk-margin-remove'}>{i18n.t('settings.dni')}</p>
                <p className={'uk-text-muted uk-text-small uk-margin-remove'}>{cardId}</p>
              </div>
              <div className={'uk-margin'}>
                <p className={'uk-text-small uk-text-secondary uk-margin-remove'}>{i18n.t('settings.gender')}</p>
                <p className={'uk-text-muted uk-text-small uk-margin-remove'}>{gender}</p>
              </div>
              <div className={'uk-margin'}>
                <p className={'uk-text-small uk-text-secondary uk-margin-remove'}>{i18n.t('settings.birthday')}</p>
                <p className={'uk-text-muted uk-text-small uk-margin-remove'}>{birthday}</p>
              </div>
              <div className={'uk-flex uk-flex-center'}>
                <button data-uk-toggle={'target: #modal-group-2'} className={'uk-button uk-button-primary'} type={'button'}>
                  {i18n.t('settings.changePassword')}
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal modalId={'modal-group-2'} closable={true}>
          <div className={'uk-modal-dialog'}>
            <ModalCloseButton />
            <div className={'uk-modal-header'}>
              <h2 className={'uk-text-bold uk-text-large'}>{i18n.t('settings.changePassword')}</h2>
            </div>
            <div className={'uk-modal-body'} data-uk-overflow-auto>
              <Initial name={initial} />
              <Password />
            </div>
          </div>
        </Modal>
        <Modal modalId={'modal-example'} closable={true}>
          <div className={'uk-modal-dialog'}>
            <ModalCloseButton />
            <div className={'uk-modal-header'}>
              <h2 className={'uk-text-bold uk-text-large'}>{i18n.t('settings.clinic')}</h2>
            </div>
            <div className={'uk-modal-body'} data-uk-overflow-auto>
              <Initial thumb={thumb} />
              <div className={'uk-margin'}>
                <p className={'uk-text-small uk-text-secondary uk-margin-remove'}>{i18n.t('settings.name')}</p>
                <p className={'uk-text-muted uk-text-small uk-margin-remove'}>{clinicName}</p>
              </div>
              <div className={'uk-margin'}>
                <p className={'uk-text-small uk-text-secondary uk-margin-remove'}>{i18n.t('settings.email')}</p>
                <p className={'uk-text-muted uk-text-small uk-margin-remove'}>{clinicEmail}</p>
              </div>
              <div className={'uk-margin'}>
                <p className={'uk-text-small uk-text-secondary uk-margin-remove'}>{i18n.t('settings.description')}</p>
                <p className={'uk-text-muted uk-text-small uk-margin-remove'}>{description}</p>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Profile;
