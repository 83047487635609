import types from '../types/player';

const init = {
  index: 0,
};

const reducer = (state = init, action) => {
  const { payload = 0 } = { ...action };
  switch (action.type) {
    case types.SET_INDEX:
      return {
        ...state,
        index: payload,
      };
    default:
      return state;
  }
};

export default reducer;
