import React from 'react';
import i18n from 'config/i18n';

const CurrentDayExercises = ({ currentExercise, currentDay }) => {
  const { exercises = [] } = currentDay;

  return (
    <aside className="new-circuit-exercise-list">
      <header className="new-circuit-exercise-list-header">
        <h3 className="new-circuit-exercise-list-header-title">{i18n.t('home.exercises')}</h3>
        <span className="new-circuit-exercise-list-header-count">
          {currentExercise + 1}/{exercises.length}
        </span>
      </header>
      <ul className="new-circuit-exercise-list-content">
        {exercises.map((exercise, index) => {
          const { id, title, image, duration } = exercise;
          const durationInMinutes = Math.round(Number(duration) / 60);
          return (
            <li key={id} className={`new-circuit-exercise-list-content-item ${index === currentExercise ? 'selected-item' : ''}`}>
              <img src={image} alt={title} className="new-circuit-exercise-list-content-img" />
              <div>
                <h4 className="new-circuit-exercise-list-content-title">{title}</h4>
                <span className="new-circuit-exercise-list-content-duration">{durationInMinutes} min</span>
              </div>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default CurrentDayExercises;
