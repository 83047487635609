import React, { useState } from 'react';
import i18n from 'config/i18n';
import moment from 'moment';
import 'moment/locale/es';

import { capitalize } from 'utils/string';
import { getPatientAvatar } from 'utils/getPatientAvatar';

import ChatBox from 'components/ChatBox';
import Button from 'components/Button';
import ModalCloseButton from 'components/ModalCloseButton';
import ReactModal from 'components/ReactModal';
import RequestCall from 'components/RequestCall';
import Nav from 'components/Nav';

import clinicAvatar from 'assets/images/clinic-avatar.png';

const Contact = (props) => {
  const { patient = {}, onRequest, onCancelCall, logs, loading, onFetchSlots, timeSlots, onJoinCall, messages } = { ...props };
  const { name: patientName, clinic = {}, email: patientEmail = '', gender = '' } = { ...patient };
  const { name: clinicName, profile = {} } = { ...clinic };
  const { avatar } = { ...profile };
  const { thumb = '' } = { ...avatar };
  const { startAt: scheduleDate = new Date() } = { ...logs };

  const [requestModalIsOpen, setRequestIsModalOpen] = useState(false);
  const [modalAlertIsOpen, setModalAlertIsOpen] = useState(false);

  const formatDayDate = (date) => {
    const mDate = moment(date);
    const dayName = capitalize(mDate.format('ddd, DD')).replace('.', '');
    const monthName = capitalize(mDate.format('MMM')).replace('.', '');
    return `${dayName} ${monthName}`;
  };

  const formatMsgDate = (date) => {
    const mDate = moment(date);
    return mDate.format(`D [${i18n.t('calls.of')}] MMMM YYYY [${i18n.t('calls.at')}] h:mma`);
  };

  const handleModal = () => setRequestIsModalOpen(!requestModalIsOpen);
  const handleModalAlert = () => setModalAlertIsOpen(!modalAlertIsOpen);

  const onRequestCall = (payload) => onRequest(payload, handleModal);
  const onCancel = () => onCancelCall(handleModalAlert);

  const buildChatBox = (message, index) => {
    const { status, message: notes, updated_at, start_at } = { ...message };
    const isActive = index === messages.length - 1;
    switch (status) {
      case 'awaiting_clinic':
        return (
          <div key={index}>
            <p className={'uk-text-meta uk-margin-small-bottom'}>
              <time className={'uk-margin-xlarge-left'} dateTime={updated_at}>
                {formatDayDate(updated_at)}
              </time>
            </p>
            <ChatBox author={patientName || patientEmail} time={updated_at}>
              <p className={'uk-comment-meta uk-margin-top uk-text-small'}>
                {i18n.t('calls.bookingMessage')}
                <span className={'uk-text-success uk-text-bold'}>{formatMsgDate(start_at)}</span>
              </p>
            </ChatBox>
            <ChatBox author={patientName || patientEmail} time={updated_at} avatar={getPatientAvatar(gender)}>
              <p className={'uk-comment-meta uk-margin-top uk-text-small'}>{notes}</p>
            </ChatBox>
          </div>
        );
      case 'rejected':
        return (
          <div key={index}>
            <p className={'uk-text-meta uk-margin-small-bottom'}>
              <time className={'uk-margin-xlarge-left'} dateTime={updated_at}>
                {formatDayDate(updated_at)}
              </time>
            </p>
            <ChatBox isLeft author={clinicName} time={updated_at}>
              <p className={'uk-comment-meta uk-margin-top uk-text-small'}>
                {i18n.t('calls.rejectedMessage')}
                <span className={'uk-text-bold uk-text-danger'}>{i18n.t('calls.rejectedMessageMed')}</span>
                {i18n.t('calls.rejectedMessageEnd')}
              </p>
            </ChatBox>
            <ChatBox isLeft author={clinicName} time={updated_at} avatar={thumb || clinicAvatar}>
              <p className={'uk-comment-meta uk-margin-top uk-text-small'}>{notes}</p>
              {isActive && (
                <div>
                  <Button className={'uk-margin-small-right'} onClick={handleModal} primary>
                    {i18n.t('calls.reschedule')}
                  </Button>
                  <Button onClick={handleModalAlert}>{i18n.t('calls.cancel')}</Button>
                </div>
              )}
            </ChatBox>
          </div>
        );
      case 'confirmed':
        return (
          <div key={index}>
            <p className={'uk-text-meta uk-margin-small-bottom'}>
              <time className={'uk-margin-xlarge-left'} dateTime={updated_at}>
                {formatDayDate(updated_at)}
              </time>
            </p>
            <ChatBox isLeft author={clinicName} time={updated_at} avatar={thumb || clinicAvatar}>
              <p className={'uk-comment-meta uk-margin-top uk-text-small'}>
                {i18n.t('calls.acceptMessage')}
                <span className={'uk-text-success uk-text-bold'}>{i18n.t('calls.acceptMessageMed')}</span>
                {i18n.t('calls.acceptMessageEnd')}
                <span className={'uk-text-success uk-text-bold'}>{formatMsgDate(scheduleDate)}</span>
              </p>
              <Button onClick={onJoinCall} primary>
                {i18n.t('calls.enter')}
              </Button>
            </ChatBox>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Nav />
      <div className={'uk-container'} style={{ paddingTop: 80 }}>
        <h2 className={'uk-text-bold uk-text-large uk-margin-top'}>{i18n.t('calls.title')}</h2>
        {!logs && logs === null && (
          <div>
            <p className={'uk-text-meta uk-margin-small-bottom'}>
              <time className={'uk-margin-xlarge-left'} dateTime={new Date()}>
                {formatDayDate(new Date())}
              </time>
            </p>
            <ChatBox isLeft author={clinicName} time={new Date()} avatar={thumb || clinicAvatar}>
              <p className={'uk-comment-meta uk-margin-top uk-text-small'}>{i18n.t('calls.greet', { clinicName })}</p>
              <Button onClick={handleModal} primary>
                {i18n.t('calls.schedule')}
              </Button>
            </ChatBox>
          </div>
        )}
        {messages.map(buildChatBox)}
        <ReactModal modalIsOpen={requestModalIsOpen} closeModal={handleModal}>
          <ModalCloseButton onClick={handleModal} />
          <div className={'uk-modal-header'}>
            <h2 className={'uk-text-bold uk-text-large'}>{i18n.t('calls.requestTitle')}</h2>
          </div>
          <div className={'uk-modal-body'}>
            <RequestCall clinic={clinic} onRequest={onRequestCall} onFetchSlots={onFetchSlots} loading={loading} timeSlots={timeSlots} />
          </div>
        </ReactModal>
        <ReactModal isWidth={'300px'} modalIsOpen={modalAlertIsOpen} closeModal={handleModalAlert}>
          <div className={'uk-padding-small'}>
            <h2 className={'uk-text-bold uk-text-large'}>{i18n.t('calls.cancelModal.title')}</h2>
            <p className={'uk-comment-meta uk-text-small'}>{i18n.t('calls.cancelModal.message')}</p>
            <div className={'uk-flex uk-flex-right'}>
              <Button onClick={handleModalAlert}>{i18n.t('calls.cancelModal.cancel')}</Button>
              <Button className={'uk-margin-small-left'} onClick={onCancel} primary>
                {i18n.t('calls.cancelModal.ok')}
              </Button>
            </div>
          </div>
        </ReactModal>
      </div>
    </>
  );
};

export default Contact;
