import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loginSura, setError } from 'store/actions/auth';
import { showNotification } from 'utils/notification';
import { useFormData } from 'utils/hooks';
import { KEY_USER } from 'config/constants';
import i18n from 'config/i18n';

import LoginSura from './LoginSura';

const emptyCredentials = {
  type: '',
  dni: '',
  password: '',
};


const ContainerLoginSura = () => {

  const authData = JSON.parse(localStorage.getItem(KEY_USER) || '{}');
  const { token = '' } = { ...authData };
  const dispatch = useDispatch();
  const { error = false, patient = {} } = useSelector((state) => {
    const { auth = {}, patient = {} } = { ...state };
    return { ...auth, patient };
  });

  const { formData, touched, handleChange, handleBlur } = useFormData(emptyCredentials);

  useEffect(() => {
    if (error) {
      showNotification('loginError', 'danger');
      dispatch(setError(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onLogin = async (params) => {
    const { dni = '', type = '', password = '' } = { ...params };

    if (isValid) {
      dispatch(loginSura({ email: `${type}${dni}`, password }))
    } else {
      showNotification('invalidFields', 'danger');
    }
  };

  const getErrors = (loginSuraData) => {
    const { type = '', dni = '', password = '' } = { ...loginSuraData };
    const errors = {};
    if (!type) errors.type = i18n.t('login.requiredType');
    if (!dni) errors.dni = i18n.t('login.requiredDni');
    if (!password) errors.password = i18n.t('login.requiredPassword');
    if (password.length < 4 && !!password) errors.password = i18n.t('login.passwordError');
    return errors;
  }

  const errors = getErrors(formData);
  const isValid = Object.keys(errors).length === 0;

  return (
    <LoginSura
      onLogin={onLogin}
      handleChange={handleChange}
      handleBlur={handleBlur}
      touched={touched}
      loginSuraCredentials={formData}
      errors={errors}
      patient={patient}
      token={token}
    />
  );
}

export default ContainerLoginSura;
