import React from 'react';
import i18n from 'config/i18n';

import './program-exercises.css';

const ProgramExercises = ({ employeeProgram }) => {
  const exercises = employeeProgram?.planDay?.exercises;
  return (
    <section className="program-lectures-container">
      <h3 className="program-lectures-title">{i18n.t('enrollmentProgram.exercises')}</h3>
      <ul className="program-lectures-list">
        {(exercises || []).map((exercise) => (
          <li key={exercise.id} className="program-lectures-lecture">
            <img src={exercise.image} alt={exercise.title} className="program-lectures-img" />
            <h4 className="program-lectures-lecture-title">{exercise.title}</h4>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default ProgramExercises;
