import React, { useState } from 'react';
import i18n from 'config/i18n';
import moment from 'moment';
import Slot from 'components/Slot';
import Icon from 'components/Icon';
import TextArea from 'components/TextArea';
import Button from 'components/Button';
import clinicAvatar from 'assets/images/clinic-avatar.png';

const RequestCall = (props) => {
  const { clinic = {}, onRequest, onFetchSlots, loading, timeSlots = [] } = { ...props };
  const { name: clinicName = '', email = '', profile = {} } = { ...clinic };
  const { avatar = {} } = { ...profile };
  const { thumb = '' } = { ...avatar };

  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [requestMessage, setRequestMessage] = useState('');
  moment.locale(`${i18n.locale}`.indexOf('es') !== -1 ? 'es' : 'en');

  const daySlots = new Array(30).fill(0).map((el, index) => moment().add(index, 'day'));

  const isRequestValid = !!date && !!time && !!requestMessage;

  const onChangeDate = (selectedDate) => {
    const currentDate = selectedDate || date;
    setDate(currentDate);
    onFetchSlots(currentDate.format('DD/MM/YYYY'));
    setTime('');
  };

  const onChangeTime = (selectedTime) => {
    const currentTime = selectedTime || time;
    setTime(currentTime);
  };

  const handleInputChange = (ev) => {
    setRequestMessage(ev.target.value);
  };

  const onRequestCall = () => {
    const dateTime = `${moment(date).format('YYYY-MM-DD')} ${moment(time).format('HH:mm ZZ')}`;
    onRequest({ startAt: dateTime, message: requestMessage });
  };

  return (
    <>
      <p className={'uk-comment-meta uk-text-small'}>{i18n.t('calls.doctor')}</p>
      <div className={'uk-flex uk-card uk-card-default uk-width-1-2@m uk-padding-small uk-margin-bottom'}>
        <div>
          <img style={{ objecFit: 'cover', borderRadius: '50%', width: 40, height: 40 }} src={thumb || clinicAvatar} alt={clinicName} />
        </div>
        <div className={'uk-margin-small-left'}>
          <p className={'uk-text-bold uk-text-small uk-margin-remove'}>{clinicName}</p>
          <div className={'uk-flex'}>
            <Icon iconType={'mail'} iconRatio={'0.9'} color={'#1e87f0'}/>
            <p style={{transform: 'translateY(-2px)'}} className={'uk-text-small uk-text-primary uk-margin-remove'}>{email}</p>
          </div>
        </div>
      </div>
      <div className={'uk-flex'}>
        <div>
          <Icon iconType={'calendar'} iconRatio={'1'} color={'#999'}/> 
        </div>
        <div className={'uk-margin-small-left'}>
          <p className={'uk-comment-meta uk-text-small uk-margin-remove'}>{i18n.t('calls.date')}</p>
          <p className={'uk-text-bold uk-text-small uk-margin-remove'}>{date ? moment(date).format('MMMM DD, YYYY') : '--/--/----'}</p>
        </div>  
      </div>
      <div className={'slots-container'}>
        {daySlots.map((slot, index) => (
          <Slot key={index} onClick={() => onChangeDate(slot)} content={slot.format('MMM D')} selected={!!date && moment(date).isSame(slot, 'day')}/>
        ))}
      </div>
      <hr/>
      <div className={'uk-flex'}>
        <div>
          <Icon iconType={'clock'} iconRatio={'1'} color={'#999'}/> 
        </div>
        <div className={'uk-margin-small-left'}>
          <p className={'uk-comment-meta uk-text-small uk-margin-remove'}>{i18n.t('calls.time')}</p>
          <p className={'uk-text-bold uk-text-small uk-margin-remove'}>{time ? moment(time).format('hh:mm A') : '--:-- --'}</p>
        </div>  
      </div>
      {(timeSlots || []).length > 0 && !!date && (
        <div className={'slots-container'}>
          {timeSlots.map((slot, index) => (
            <Slot key={index} onClick={() => onChangeTime(moment(slot, 'HH:mm'))} dateSlot content={moment(slot, 'HH:mm').format('hh:mm A')} selected={!!time && time.format('hh:mm A') === moment(slot, 'HH:mm').format('hh:mm A')}/>
          ))}
        </div> 
      )}
      {(timeSlots || []).length === 0 && !!date && !loading && (
        <div className={'uk-flex uk-card uk-card-default uk-padding-small uk-margin-top'}>
          <div>
            <Icon iconType={'calendar'} iconRatio={'2'} color={'#666'} />
          </div>
          <div className={'uk-margin-small-left'}>
            <p className={'uk-text-bold uk-text-small uk-margin-remove'}>{i18n.t('calls.notAvailability')}</p>
            <p className={'uk-comment-meta uk-text-small uk-margin-remove'}>{i18n.t('calls.notAvailabilityMsg')}</p>
          </div>
        </div>
      )}
      <hr/>
      <TextArea
        label={i18n.t('calls.message')} 
        name={'requestMessage'}
        widthModifier={'setWidth'}
        rows={3}
        value={requestMessage}
        onChange={handleInputChange}
      />
      <div className={'uk-flex uk-flex-center uk-margin'}>
        <Button onClick={onRequestCall} primary={isRequestValid} disabled={!isRequestValid}>
          <Icon iconType={'receiver'} iconRatio={'0.9'} color={isRequestValid ? '#fff' : '#999'} />
          {i18n.t('calls.scheduleCall')}
        </Button> 
      </div> 
    </>
  );
}

export default RequestCall;