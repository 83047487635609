import types from '../types/programs';

const init = {
  recommendedPrograms: undefined,
  employeePrograms: undefined,
  completed: undefined,
  successReminder: undefined,
};

const programsReducer = (state = init, action) => {
  const { payload = null } = { ...action };
  switch (action.type) {
    case types.SET_RECOMMENDED_PROGRAMS:
      return {
        ...state,
        recommendedPrograms: payload,
      };
    case types.SET_EMPLOYEE_PROGRAMS:
      return {
        ...state,
        employeePrograms: payload,
      };
    case types.SET_SUCCESS_ENROLL:
      return {
        ...state,
        completed: payload,
      };
    case types.SET_SUCCESS_REMINDER:
      return {
        ...state,
        successReminder: payload,
      };
    default:
      return state;
  }
};

export default programsReducer;
