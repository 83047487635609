import React from 'react';
import i18n from 'config/i18n';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'utils/constants';

import Modal from 'components/ReactModal';

import successImage from 'assets/images/success-img.png';

import './enroll-success-modal.css';

const EnrollSuccessModal = ({ modalIsOpen, closeModal }) => {
  const { push } = useHistory();

  return (
    <Modal modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <section className="enroll-success-bg"> </section>
      <img src={successImage} alt="enroll success" className="enroll-success-img" />
      <div className="enroll-success-content">
        <h2 className="enroll-success-title">{i18n.t('enrollmentProgram.successEnroll.title')}</h2>
        <p className="enroll-success-subtitle">{i18n.t('enrollmentProgram.successEnroll.caption')}</p>
        <button className="enroll-button enroll-program-btn" onClick={() => push(ROUTES.HOME, { refetch: true })}>
          {i18n.t('enrollmentProgram.successEnroll.btn')}
        </button>
      </div>
    </Modal>
  );
};

export default EnrollSuccessModal;
