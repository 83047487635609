import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, setError } from 'store/actions/auth';
import { EMAIL_REGEX, KEY_USER } from 'config/constants';
import i18n from 'config/i18n';
import { showNotification } from 'utils/notification';
import { useFormData } from 'utils/hooks';
import Login from './Login';

const emptyCredentials = {
  email: '',
  password: '',
};

const Container = () => {
  const authData = JSON.parse(localStorage.getItem(KEY_USER) || '{}');
  const { token = '' } = { ...authData };
  const dispatch = useDispatch();
  const { error = false /*user = undefined, logout: isLogout = false,*/ /*loading,*/ } = useSelector((state) => {
    const { auth = {}, ui = {}, patient = {} } = { ...state };
    return { ...auth, loading: ui.loading, patient };
  });

  const { formData, touched, handleChange, handleBlur } = useFormData(emptyCredentials);

  useEffect(() => {
    if (error) {
      showNotification('loginError', 'danger');
      dispatch(setError(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onLogin = async (params) => {
    if (isValid) {
      dispatch(login(params));
    } else {
      showNotification('invalidFields', 'danger');
    }
  };

  const getErrors = (loginData) => {
    const { email = '', password = '' } = { ...loginData };
    const errors = {};
    if (!email) errors.email = i18n.t('login.requiredEmail');
    if (!EMAIL_REGEX.test(email) && !!email) errors.email = i18n.t('login.emailError');
    if (!password) errors.password = i18n.t('login.requiredPassword');
    if (password.length < 6 && !!password) errors.password = i18n.t('login.passwordError');
    return errors;
  };

  const errors = getErrors(formData);
  const isValid = Object.keys(errors).length === 0;

  return (
    <Login
      onLogin={onLogin}
      handleChange={handleChange}
      handleBlur={handleBlur}
      touched={touched}
      loginCredentials={formData}
      errors={errors}
      token={token}
    />
  );
};

export default Container;
