import types from '../types/programs';

export const setError = (payload) => ({ type: types.SET_ERROR, payload });
export const getRecommendedPrograms = (payload) => ({ type: types.GET_RECOMMENDED_PROGRAMS, payload });
export const setRecommendedPrograms = (payload) => ({ type: types.SET_RECOMMENDED_PROGRAMS, payload });
export const getEmployeePrograms = (payload) => ({ type: types.GET_EMPLOYEE_PROGRAMS, payload });
export const setEmployeePrograms = (payload) => ({ type: types.SET_EMPLOYEE_PROGRAMS, payload });
export const enrollToProgram = (payload) => ({ type: types.ENROLL_TO_PROGRAM, payload });
export const setSuccessEnroll = (payload) => ({ type: types.SET_SUCCESS_ENROLL, payload });
export const updateCurrentProgramDay = (payload) => ({ type: types.UPDATE_CURRENT_PROGRAM_DAY, payload });
export const setReminder = (payload) => ({ type: types.SET_REMINDER, payload });
export const setSuccessReminder = (payload) => ({ type: types.SET_SUCCESS_REMINDER, payload });
export const unactivePatientProgram = (payload) => ({ type: types.UNACTIVE_PROGRAM, payload });
