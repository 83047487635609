import React from 'react';

const ImageChatBox = ({ avatar, author, isLeft }) => (
  <>
    <img
      data-uk-img
      className={`uk-margin-small-${isLeft ? 'right' : 'left'}`}
      style={{ objectFit: 'cover', borderRadius: '50%', width: 50, height: 50, }}
      src={avatar}
      alt={author}
    />
  </>
);

export default ImageChatBox;