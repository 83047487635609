import { GRAPHQL_API } from 'config/constants';
import { GraphQLClient } from 'graphql-request';

export const getAuthClient = (token) => {
  return new GraphQLClient(GRAPHQL_API, {
    headers: {
      Authorization: `${token}`,
      Role: 'patient',
    },
  });
};
