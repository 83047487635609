import React from 'react';
import { HiArrowRight, HiArrowLeft } from 'react-icons/hi';
import { FaCalendarCheck /* FaPlay, FaPause */ } from 'react-icons/fa';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import i18n from 'config/i18n';

import { useToggle } from 'utils/hooks';

import VideoPlayer from 'components/VideoPlayer';
import ExerciseGuide from 'components/ExerciseGuide';

const ExerciseVideo = ({ currentExercise, currentDay, setCurrentExercise, setIsAlertConfirm }) => {
  const [exerciseGuide, setExerciseGuide] = useToggle();

  const handleBackExercise = () => {
    setCurrentExercise((prev) => prev - 1);
  };
  const handleNextExercise = () => {
    setCurrentExercise((prev) => prev + 1);
  };

  const { exercises = [] } = currentDay;
  const isFirstExercise = currentExercise === 0;
  const isLastExercise = currentExercise === exercises.length - 1;
  const getCurrentExercise = exercises.find((_, index) => index === currentExercise);
  const title = getCurrentExercise?.title;
  const duration = getCurrentExercise?.duration;
  const reps = getCurrentExercise?.reps || 10;
  const sets = getCurrentExercise?.sets;
  const bodyParts = getCurrentExercise?.bodyParts;
  const equipments = getCurrentExercise?.equipments;
  const durationInMinutes = Math.round(Number(duration) / 60);
  const joinedBodyParts = bodyParts?.join(' · ');

  return (
    <>
      <div className="new-circuit-exercise">
        <section>
          {exercises.map((exercise, index) => {
            if (index === currentExercise) {
              return (
                <div className="new-circuit-video-container">
                  <VideoPlayer
                    source={exercise?.video}
                    autoplay
                    style={{
                      width: '100%',
                      height: 324,
                    }}
                  />
                  <AiOutlineExclamationCircle className="new-circuit-exercise-guide-btn" onClick={setExerciseGuide} />
                </div>
              );
            }
            return null;
          })}
        </section>
        <section className="new-circuit-exercise-info">
          <div style={{ marginBottom: 8 }}>
            <h3 className="new-circuit-exercise-info-title">{title}</h3>
            <span className="new-circuit-exercise-info-data">
              {durationInMinutes} min · {i18n.t('dayDetail.exData', { reps, sets })} · {joinedBodyParts}
            </span>
          </div>
          <div className="program-divider" />
          <h3 className="new-circuit-exercise-info-title-objects">{i18n.t('exercise.requiredObjects')}</h3>
          <ul className="new-circuit-exercise-info-objects">
            {(equipments || []).map((item) => (
              <li key={item} className="new-circuit-exercise-info-object">
                {item.toUpperCase()}
              </li>
            ))}
          </ul>
        </section>
        <footer className="new-circuit-exercise-controls">
          <button
            className={`controls-layout controls-back ${isFirstExercise ? 'controls-back-disabled' : ''}`}
            onClick={handleBackExercise}>
            <HiArrowLeft />
          </button>
          <button className="controls-layout controls-next" onClick={isLastExercise ? setIsAlertConfirm : handleNextExercise}>
            {isLastExercise ? <FaCalendarCheck /> : <HiArrowRight />}
          </button>
        </footer>
      </div>
      {exerciseGuide && <ExerciseGuide modalIsOpen={exerciseGuide} closeModal={setExerciseGuide} exercise={getCurrentExercise} />}
    </>
  );
};

export default ExerciseVideo;
