import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';
import { useDispatch, useSelector } from 'react-redux';

import { getPatientInfo } from 'store/actions/patient';
import { setUser } from 'store/actions/auth';
import { KEY_USER } from 'config/constants';
import { ROUTES } from 'utils/constants';

import Feedback from 'views/Feedback';
import Login from 'views/Login';
import LoginSura from 'views/LoginSura';
import Home from 'views/Home';
import Plan from 'views/Plan';
import Circuit from 'views/Circuit';
import Profile from 'views/Profile';
import Notifications from 'views/Notifications';
import Contact from 'views/Contact';
import Call from 'views/Call';
import EnrollProgram from 'views/EnrollProgram';
import Program from 'views/Program';
import Lectures from 'views/Lectures';
import NewCircuit from 'views/NewCircuit';
import Loader from 'components/Loader';

export const AppContainer = () => {
  const authData = JSON.parse(localStorage.getItem(KEY_USER) || '{}');
  const { token = '' } = { ...authData };
  const dispatch = useDispatch();
  const { plan = {}, ui } = useSelector((state) => {
    const { ui = {}, patient = {} } = { ...state };
    return { ...patient, ui };
  });
  const { patient: patientData = {} } = { ...plan };

  const { clinic = {} } = { ...patientData };
  const { profile: clinicProfile } = { ...clinic };
  const { hasCalls = false } = { ...clinicProfile };
  const { loading = false } = { ...ui };

  useEffect(() => {
    dispatch(getPatientInfo({ token }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setUser(authData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={'healthphy-container'}>
      {loading && <Loader />}
      <Router>
        <Switch>
          <Route exact path={ROUTES.LOGIN} component={Login} />
          <Route exact path={'/login-sura'} component={LoginSura} />
          <PrivateRoute exact path={ROUTES.HOME} component={Home} />
          <PrivateRoute exact path={ROUTES.PLAN} component={Plan} />
          <PrivateRoute exact path={ROUTES.ENROLL_PROGRAM} component={EnrollProgram} />
          <PrivateRoute exact path={ROUTES.PROGRAM} component={Program} />
          <PrivateRoute exact path={ROUTES.LECTURES} component={Lectures} />
          <PrivateRoute exact path={'/circuit'} component={Circuit} />
          <PrivateRoute exact path={ROUTES.NEW_CIRCUIT} component={NewCircuit} />
          <PrivateRoute exact path={'/feedback/:id'} component={Feedback} />
          <PrivateRoute exact path={ROUTES.SETTINGS} component={Profile} />
          <PrivateRoute exact path={ROUTES.NOTIFICATIONS} component={Notifications} />
          {hasCalls && <PrivateRoute exact path={ROUTES.CONTACT} component={Contact} />}
          {hasCalls && <PrivateRoute exact path={ROUTES.CALL} component={Call} />}
        </Switch>
      </Router>
    </div>
  );
};
