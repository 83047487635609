import types from '../types/contact';

const init = {
  logs: undefined,
  error: false,
  requestError: false,
  token: '',
  ready: false,
  slots: [],
};

const reducer = (state = init, action) => {
  const { payload = null } = { ...action };
  switch (action.type) {
    case types.SET_ERROR:
      return {
        ...state,
        error: payload,
      };
    case types.SET_TOKEN:
      const { token, ready } = { ...payload };
      return {
        ...state,
        token,
        ready,
      };
    case types.GET_TOKEN:
      return {
        ...state,
        token: '',
        ready: false,
      };
    case types.SET_LOGS:
      return {
        ...state,
        logs: payload,
      };
    case types.SET_SLOTS:
      return {
        ...state,
        slots: payload,
      };
    case types.SET_REQUEST_ERROR:
      return {
        ...state,
        requestError: payload,
      };
    default:
      return state;
  }
};

export default reducer;
