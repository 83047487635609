export const GET_LOGS = `query bookingLogs($bookingId: String) {
  bookingLogs (bookingId: $bookingId) {
      id
      status
      message
      startAt
      endAt
      versions {
        id
        event
        logs
      }
    }
  }`;

export const GET_SLOTS = `query availableSlots($day: String!) {
    availableSlots (day: $day)
    }`;

export const CALL_REQUEST = `mutation createBookingRequest($startAt: String!, $message: String) {
  createBookingRequest(startAt: $startAt, message: $message) {
      errors
      booking {
        id
      }
    }
  }`;

export const UPDATE_REQUEST = `mutation updateBookingRequest($bookingId: String!, $status: String!, $startAt: String, $message: String) {
  updateBookingRequest(bookingId: $bookingId, status: $status, startAt: $startAt, message: $message) {
        errors
        booking {
          id
        }
      }
    }`;

export const GENERATE_TOKEN = `mutation generateBookingToken($bookingId: String!) {
      generateBookingToken(bookingId: $bookingId) {
            errors
            token
            ready
          }
        }`;
