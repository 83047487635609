import { take, put, fork, all } from 'redux-saga/effects';
import { KEY_PAIN_COUNT, KEY_USER } from 'config/constants';
import types from 'store/types/patient';
import {
  setPatientPlan,
  setError,
  getPatientPlan as getPatientPlanAct,
  setPatientInfo,
  setCompleted,
  setFinished,
} from 'store/actions/patient';
import { logoutRequest } from 'store/actions/auth';
import { showLoading, hideLoading } from 'store/actions/ui';
import {
  GET_PATIENT_PLAN,
  MARK_AS_COMPLETED,
  SEND_FEEDBACK,
  SEND_PAIN_LEVEL,
  GET_PATIENT_INFO,
  GET_EMPLOYEE_INFO,
  MARK_AS_READ,
} from 'store/queries/patient';
import i18n from 'config/i18n';
import { getAuthClient } from 'utils/graphql';
import { logEvent, Event } from 'config/events';

function* getPatientPlan(token, navigate) {
  try {
    yield put(showLoading());
    const client = getAuthClient(token);
    const { getPatientPlan: plans = [] } = yield client.request(GET_PATIENT_PLAN);
    const [plan = {}] = [...(plans || [])];
    const { id = '', patient = {} } = { ...plan };
    const { status = 'active' } = { ...patient };
    if (id) {
      if (status === 'active') {
        yield put(setPatientPlan(plan));
      } else {
        yield put(logoutRequest());
        alert(
          i18n.t('login.inactiveTitle'),
          i18n.t('login.inactiveMessage'),
          [{ text: i18n.t('login.inactiveOk'), onPress: () => !!navigate && navigate('login') }],
          { cancelable: false },
        );
      }
    }
  } catch (error) {
    yield put(setError(true));
    console.log(error);
    if (`${error}`.indexOf('authenticate to perform this action') !== -1) {
      yield put(logoutRequest());
      yield localStorage.removeItem(KEY_USER);
      !!navigate && navigate('login');
    }
  } finally {
    yield put(hideLoading());
  }
}

function* markAsRead(notificationId, token, callback) {
  try {
    yield put(showLoading());
    const client = getAuthClient(token);
    yield client.request(MARK_AS_READ, { notificationId });
    callback && callback();
  } catch (error) {
    yield put(setError(true));
    console.log(error);
  } finally {
    yield put(getPatientPlanAct({ token }));
    yield put(hideLoading());
  }
}

function* getPatientInfo(token, navigate, patientType) {
  try {
    yield put(showLoading());
    const client = getAuthClient(token);
    const response = yield client.request(patientType === 'Employee' ? GET_EMPLOYEE_INFO : GET_PATIENT_INFO);
    const { getPatientInfo: patient = {}, getEmployeeInfo: employee = {} } = { ...response };
    const { status = 'active', id, clinic = {}, company = {} } = { ...patient, ...employee };
    if (id) {
      if (status === 'active') {
        yield put(setPatientInfo({ ...employee, ...patient, clinic: { ...company, ...clinic } }));
        yield put(getPatientPlanAct({ token, navigate }));
      } else {
        yield put(logoutRequest());
        alert(
          i18n.t('login.inactiveTitle'),
          i18n.t('login.inactiveMessage'),
          [{ text: i18n.t('login.inactiveOk'), onPress: () => !!navigate && navigate('login') }],
          { cancelable: false },
        );
      }
    }
  } catch (error) {
    yield put(setError(true));
    console.log(error);
    if (`${error}`.indexOf('authenticate to perform this action') !== -1) {
      yield put(logoutRequest());
      yield localStorage.removeItem(KEY_USER);
      !!navigate && navigate('login');
    }
  } finally {
    yield put(hideLoading());
  }
}

function* markAsCompleted(planId, startAt, token) {
  try {
    yield put(showLoading());
    const client = getAuthClient(token);
    const { updateCurrentDay = {} } = yield client.request(MARK_AS_COMPLETED, { planId, startAt });
    const { errors = [], patientPlan = {} } = { ...updateCurrentDay };
    const { completed = false, patient = {} } = { ...patientPlan };
    const { cardId = '', name = '', email = '', id = '' } = { ...patient };
    if (errors && errors.length > 0) {
      yield put(setError(true));
    } else {
      if (completed) {
        yield put(setFinished(true));
        logEvent(Event.PLAN_COMPLETED, { planId, cardId, name, email, id });
      } else {
        yield put(setCompleted(true));
        logEvent(Event.DAY_COMPLETED, { planId, cardId, name, email, id });
      }

      const storeValue = localStorage.getItem(KEY_PAIN_COUNT);
      localStorage.setItem(KEY_PAIN_COUNT, `${Number(storeValue) + 1}`);
      yield put(getPatientPlanAct({ token }));
    }
  } catch (error) {
    yield put(setError(true));
    console.log(error);
  } finally {
    yield put(hideLoading());
  }
}

function* sendFeedback(data, token, callback) {
  try {
    yield put(showLoading());
    const client = getAuthClient(token);
    yield client.request(SEND_FEEDBACK, { feedbackInput: data });
  } catch (error) {
    yield put(setError(true));
    console.log(error);
  } finally {
    yield put(getPatientPlanAct({ token }));
    yield put(hideLoading());
    callback && callback();
  }
}

function* sendPainLevel(painLevel, token, callback) {
  try {
    yield put(showLoading());
    const client = getAuthClient(token);
    yield client.request(SEND_PAIN_LEVEL, { painLevel });
    callback && callback();
  } catch (error) {
    yield put(setError(true));
    console.log(error);
  } finally {
    yield put(getPatientPlanAct({ token }));
    yield put(hideLoading());
  }
}

function* watchPatientPlan() {
  while (true) {
    const { payload = {} } = yield take(types.GET_PATIENT_PLAN);
    const { token = '', navigate } = { ...payload };
    yield fork(getPatientPlan, token, navigate);
  }
}

function* watchPatientInfo() {
  while (true) {
    const { payload = {} } = yield take(types.GET_PATIENT_INFO);
    const { token = '', navigate, patientType } = { ...payload };
    yield fork(getPatientInfo, token, navigate, patientType);
  }
}

function* watchRead() {
  while (true) {
    const { payload = {} } = yield take(types.MARK_AS_READ);
    const { token = '', notificationId, callback = () => {} } = { ...payload };
    yield fork(markAsRead, notificationId, token, callback);
  }
}

function* watchCompleted() {
  while (true) {
    const { payload = {} } = yield take(types.MARK_AS_COMPLETED);
    const { token = '', planId = '', startAt = undefined } = { ...payload };
    yield fork(markAsCompleted, planId, startAt, token);
  }
}

function* watchFeedback() {
  while (true) {
    const { payload = {} } = yield take(types.SEND_FEEDBACK);
    const {
      token = '',
      difficulty: difficultyLevel = 1,
      comments = '',
      like: satisfactionLevel = 5,
      callback = () => {},
      feedbackableType = 'Day',
      feedbackableId = '',
    } = { ...payload };
    yield fork(sendFeedback, { difficultyLevel, feedbackableType, feedbackableId, satisfactionLevel, comments }, token, callback);
  }
}

function* watchPainLevel() {
  while (true) {
    const { payload = {} } = yield take(types.SEND_PAIN_LEVEL);
    const { token = '', painLevel = 0, callback = () => {} } = { ...payload };
    yield fork(sendPainLevel, painLevel, token, callback);
  }
}

export default function* root() {
  yield all([
    fork(watchPatientPlan),
    fork(watchCompleted),
    fork(watchFeedback),
    fork(watchPainLevel),
    fork(watchPatientInfo),
    fork(watchRead),
  ]);
}
