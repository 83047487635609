import React from 'react';

const styles = {
  success: 'uk-label-success',
  warning: 'uk-label-warning',
  danger: 'uk-label-danger',
};

const Label = (props) => {
  const { labelType = '', children } = { ...props };

  return (
    <span className={`uk-margin-small-right uk-label ${styles[labelType]}`}>
      {children}
    </span>
  );
};

export default Label;