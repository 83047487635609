import types from '../types/auth';

export const setUser = (payload) => ({ type: types.SET_USER, payload });
export const setUpdated = (payload) => ({ type: types.SET_UPDATED, payload });
export const setSent = (payload) => ({ type: types.SET_SENT, payload });
export const setError = (payload) => ({ type: types.SET_ERROR, payload });
export const login = (payload) => ({
  type: types.LOGIN,
  payload,
});
export const loginSura = (payload) => ({
  type: types.LOGIN_SURA,
  payload,
});
export const logoutRequest = () => ({ type: types.LOGOUT_REQUEST });
export const logout = () => ({ type: types.LOGOUT });
export const changePassword = (payload) => ({
  type: types.CHANGE_PASSWORD,
  payload,
});
export const sendFeedback = (payload) => ({ type: types.SEND_FEEDBACK, payload });
export const reset = (payload) => ({
  type: types.RESET,
  payload,
});
