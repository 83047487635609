import React from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { queryParams } from 'utils/string';
import { markAsCompleted } from 'store/actions/patient';
import { KEY_USER } from 'config/constants';

import Plan from './Plan';

const ContainerPlan = () => {
  const {
    location: { search },
  } = useHistory();
  const { id: planId } = queryParams(search);
  const authData = JSON.parse(localStorage.getItem(KEY_USER) || '{}');
  const { token = '', patientType = '' } = { ...authData };
  const isEmployee = patientType === 'Employee';

  const dispatch = useDispatch();
  const { plan = {} } = useSelector((state) => {
    const { patient = {} } = state;
    return patient;
  });

  const { patient = {} } = plan;
  const { patientPlans = [] } = patient;
  const currentPlan = patientPlans.find((patientPlan) => patientPlan.id === planId);
  const getPlan = isEmployee ? currentPlan : plan;

  const onCompleteDay = async (timestamp, callback) => {
    await dispatch(markAsCompleted({ token, planId: getPlan.id, startAt: timestamp ? `${timestamp}` : undefined }));
    !!callback && callback();
  };

  return <Plan plan={getPlan} onCompleteDay={onCompleteDay} />;
};

export default ContainerPlan;
