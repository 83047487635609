import React from 'react';
import clinicAvatar from 'assets/images/clinic-avatar.png';

const Initial = ({ name, thumb }) => (
  <div className={'initial-container uk-margin-auto'}>
    {name ? (
      <p className={'uk-text-bold uk-text-large uk-margin-remove'}>{name}</p>
    ) : (
      <img 
      	style={{...(!!thumb && {borderRadius: '50%', width: 80, height: 80, objectFit: 'cover'})}} 
      	src={!!thumb ? thumb : clinicAvatar} 
      	alt={'clinic avatar'} 
      />
    )}
  </div>
);

export default Initial;